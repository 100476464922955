import Notificacao from '../../../components/notification';
import { getUrlAPIv4 } from '../api';

const getTaxaEntrega = async (
    bairroId: number,
    empresaId: number,
    latitude: number | null,
    longitude: number | null,
    isPalmas: boolean,
    token: string,
): Promise<number | null> => {

    const payload = !isPalmas ? {
        "bairro_id": bairroId,
        "empresa_id": empresaId,
    } : {
        "bairro_id": 0,
        "empresa_id": empresaId,
        "latitude": latitude,
        "longitude": longitude
    }

    try {
        let url = `${getUrlAPIv4}/user/endereco_taxa_convidado`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(payload)
        });
        if (response.status === 200) {
            const data = await response.json();
            return data.valor;
        }
        else if (response.status === 401) {
            const mensagem = 'Sua sessão expirou, faça login novamente!';
            Notificacao('Error', mensagem, 'danger');
            localStorage.removeItem('user')
            localStorage.removeItem('address_user')
            window.location.href = '/login';
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }

};

export default getTaxaEntrega;