import { Link } from 'react-router-dom'
import styles from './styles.module.css'

export const BottomTrabalheCononosco = () => {
    return <Link to='/formTrabalheConosco' className={styles.button}>
        <div >
            Trabalhe Conosco
        </div>
    </Link>

}