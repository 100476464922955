//MUI

import Box from '@mui/material/Box/Box'
import { useEffect, useState } from 'react'

//CSS
import styles from './style.module.css'

//IMAGENS
const imgCronometro = require('../../../../assets/images/cronometro.png')
const imgEntregador = require('../../../../assets/images/entregador.png')

function SecaoEntregador() {

  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight
  });
  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight
    });
  useEffect(() => (window.onresize = updateSize), []);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: size.x > 730 ? '8%' : '15%',
    }}>


      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
      }}>

        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'start',
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: '10px',
          }}>
            <Box
              sx={{
                width: size.x > 700 ? 172.79 : '100%',
                display: 'flex',
                background: 'rgba(255, 236, 13, 0.52)',
                borderRadius: 1,
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                transform: 'matrix(1, 0, 0, 1, 0, 0)',
                marginLeft: '5px',
                marginRight: '5px',
                marginTop: '10px',
                cursor: 'pointer',
              }}
              className={styles.div_delivery}
            >
              <img
                src={imgCronometro}
                alt="cronometro"
                className={styles.cronometro}
              />
              <p className={styles.deliveryall}>Delivery de tudo</p>
            </Box>
            <Box
              sx={{
                width: size.x > 700 ? 172.79 : '100%',
                display: 'flex',
                background: '#F9146B',
                borderRadius: 1,
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                transform: 'matrix(1, 0, 0, 1, 0, 0)',
                marginLeft: '5px',
                marginRight: '5px',
                marginTop: '10px',
                cursor: 'pointer',
              }}
              className={styles.div_delivery}
              onClick={() => window.open('https://onelink.to/6qh3rv')}
            >
              <p className={styles.deliveryall} style={{
                color: '#FFF',
              }}>Baixe o APP</p>
            </Box>
            <Box
              sx={{
                width: size.x > 700 ? 172.79 : '100%',
                display: 'flex',
                background: '#F9146B',
                borderRadius: 1,
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                transform: 'matrix(1, 0, 0, 1, 0, 0)',
                marginLeft: '5px',
                marginRight: '5px',
                marginTop: '10px',
                cursor: 'pointer',
              }}
              className={styles.div_delivery}
              onClick={() => window.open('/login',)}
            >
              <p className={styles.deliveryall} style={{
                color: '#FFF',
              }}>Faça seu pedido</p>
            </Box>
          </Box>
          <h1 className={styles.titulo}>
            O que você quiser,
            <br /> Onde você estiver!
          </h1>
        </Box>

        <p className={styles.fraseslogan}>
          Somos um aplicativo de delivery desenvolvido com propósito de
          facilitar a vida de todos.
        </p>
      </Box>


      <Box className={styles.fundoAmarelo}>
        <img
          src={imgEntregador}
          alt="entregador com pizza"
          className={styles.entragadorcompizza}
        />
      </Box>

    </Box>
  )
}
export default SecaoEntregador
/*
<React.Fragment>
<CssBaseline />
<Container maxWidth="sm">
 <div className='divfundoamarelo'>
            </div>
            <img src={imgEntregador} alt="entregador com pizza" className='entragadorcompizza' />
</Container>
</React.Fragment>


<Box color="text.primary" >
            <div className='divfundoamarelo'>
            </div>
            <img src={imgEntregador} alt="entregador com pizza" className='entragadorcompizza' />
        </Box><Box color="text.primary" >
            <div className='divfundoamarelo'>
            </div>
            <img src={imgEntregador} alt="entregador com pizza" className='entragadorcompizza' />
        </Box>




*/
