import { Login, Logout, Search } from '@mui/icons-material';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Grid, InputAdornment, ListItemIcon, Menu, MenuItem, TextField, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { ModalCustom } from '../../../components/modal';
import Notificacao from '../../../components/notification';
import { useCart } from '../context/cartcontext';
import getBusiness from '../controllers/getBusiness'
import getCategorias from '../controllers/getCategoria'
import { getImageProfile } from '../controllers/getImageProfile';
import getProductsSearch from '../controllers/getProdutoSearch'
import { Root as BannerModel } from '../model/banners.model';
import { Daum as CategoriaModel } from '../model/categoria.model';
import { Root as EmpresaModel } from '../model/empresas.model';
import { EnderecoCliente } from '../model/endereco.model';
import { Root as ProdutoPesquisa } from '../model/pesquisaProduto.model';
import { Root as UserModel } from '../model/user.model';
import FALLBACK_IMAGE from '../onerror.png';
import ModalEnderecos from './modal/detalhesEndereco/modalEnderecos';
import ModalProdutos from './modal/produtos/modalProdutos';
import { Carrocel } from './widgets/carrocel';
import { getInfoShopingCart } from './widgets/shopingCartFloating'

const EmpresasFromCity = () => {

    const { cart, setCartAndSave } = useCart();

    const [loading, setLoading] = useState(true);
    const [isLoadingCategoria, setIsLoadingCategoria] = useState(true);
    const [isLoadingBanner, setIsLoadingBanner] = useState(true);
    const [business, setBusiness] = useState<EmpresaModel[]>([]);
    const [categorias, setCategorias] = useState<CategoriaModel[]>([]);
    const [banners, setBanners] = useState<BannerModel[]>([]);
    const [bannerFirst, setBannersFirst] = useState<BannerModel | null>(null);
    const [error, setError] = useState(false);
    const [errorCategoria, setErrorCategoria] = useState(false);
    const [errorBanner, setErrorBanner] = useState(false);
    const [openModalPromocoes, setOpenModalPromocoes] = useState(false);
    const [openModalEnderecos, setOpenModalEnderecos] = useState(false);
    const [openModalProdutoByPesquisa, setOpenModalProdutoByPesquisa] = useState(false);
    const [idProdutoByPesquisa, setIdProdutoByPesquisa] = useState(-1);
    const [idEmpresaByPesquisa, setIdEmpresaByPesquisa] = useState(-1);
    const [nomeProdutoByPesquisa, setNomeProdutoByPesquisa] = useState('');
    const [search, setSearch] = useState('')
    const [produtoSearch, setProdutoSearch] = useState<ProdutoPesquisa[]>([]) 
    const [idDasEmpresasAbertas, setIdDasEmpresasAbertas] = useState<number[]>([])
    const [isLoadingSearch, setIsLoadingSearch] = useState(false)
    const [entityId, setEntityId] = useState(-1)
    const [openInfoPerson, setOpenInfoPerson] = useState(false)

    const [size, setSize] = useState({
        x: window.innerWidth,
        y: window.innerHeight
    });
    const updateSize = () =>
        setSize({
            x: window.innerWidth,
            y: window.innerHeight
        });
    useEffect(() => (window.onresize = updateSize), []);

    const { id, cidade } = useParams()

    const onMediaFallback = (event: any) => event.target.src = FALLBACK_IMAGE;

    const handleOpenModalPromocoes = () => setOpenModalPromocoes(true);
    const handleCloseModalPromocoes = () => setOpenModalPromocoes(false);

    const handleOpenModalEnderecos = () => setOpenModalEnderecos(true);
    const handleCloseModalEnderecos = () => setOpenModalEnderecos(false);

    const handleOpenModalProdutoByPesquisa = () => setOpenModalProdutoByPesquisa(true);
    const handleCloseModalProdutoByPesquisa = () => setOpenModalProdutoByPesquisa(false);

    const [handleOnReload, setHandleOnReload] = useState(false);

    const userInString = localStorage.getItem('user') || '{}'
    const user = JSON.parse(userInString) as UserModel

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    async function getUserEntityID(): Promise<void> {
        if (user.user.entity_id > 0) {
            setEntityId(user.user.entity_id)
        }
    }

    useEffect(() => {
        getUserEntityID()
    }, [])

    const [imageUrl, setImageUrl] = useState(FALLBACK_IMAGE)

    async function getImage() {
        const userInString = await localStorage.getItem('user') || '{}'
        if (userInString.length < 5) return
        const user = JSON.parse(userInString) as UserModel
        const image = await getImageProfile(user.user.id)
        if (image) {
            setImageUrl(image)
        }
    }

    async function checkRouter(): Promise<void> {
        if (handleOnReload) {
            const address = await JSON.parse(await localStorage.getItem('address_user') || '{}') as EnderecoCliente
            if (address) {
                if (address.cidade !== cidade) {
                    const url = `/empresas/cidade/${address.cidades_id}/${address.cidade}`
                    window.location.href = url
                }
            }
        }
    }

    useEffect(() => {
        checkRouter()
        getEmpresas();
        getCategoriasCity();
        getBannersAPI();
        getImage()
        localStorage.setItem('infoProfile', '{}')
    }, [handleOnReload]);

    async function getEmpresas(): Promise<void> {
        if (id) {
            setLoading(true);
            const dados = await getBusiness(parseInt(id));
            if (dados) {
                const [empresas, idDasEmpresas] = dados;
                setIdDasEmpresasAbertas(idDasEmpresas);
                if (empresas) {
                    setError(false);
                    setBusiness(empresas);
                }
            } else {
                setError(true);
            }
            setLoading(false);
        }
    }

    async function getCategoriasCity(): Promise<void> {
        if (id) {
            setIsLoadingCategoria(true);
            const categorias = await getCategorias(cidade);
            if (categorias) {
                setErrorCategoria(false);
                setCategorias(categorias);
            } else {
                setErrorCategoria(true);
            }
            setIsLoadingCategoria(false);
            setErrorCategoria(false);
        }
    }

    async function getBannersAPI(): Promise<void> {
        if (id) {
            setIsLoadingBanner(true);
            //const banners = await getBanners(parseInt(id));
            if (true) {
                setErrorBanner(false);
                //setBanners(banners);
            } else {
                setErrorBanner(true);
            }
            setIsLoadingBanner(false);
            setErrorBanner(false);
        }
    }

    async function getProdutsAPI(textoDaPesquisa: string): Promise<void> {
        if (textoDaPesquisa.length > 2) {
            setIsLoadingSearch(true);
            const produtos = await getProductsSearch(textoDaPesquisa, idDasEmpresasAbertas, size.x > 700 ? 8 : 4);
            if (produtos) {
                setProdutoSearch(produtos);
            }
            setIsLoadingSearch(false);
        } else {
            setProdutoSearch([]);
        }
    }

    function searchEmpresas(): EmpresaModel[] {
        if (search !== '') {
            let empresasFlag = [];
            for (let b in business) {
                let listEmpresasPesquisa: number[] = [];
                produtoSearch.forEach((item) => {
                    listEmpresasPesquisa.push(item.empresas_id)
                })
                let idBusinessInList = listEmpresasPesquisa.includes(business[b].id);
                let searchInName = business[b].nome.toLowerCase().includes(search.toLowerCase())
                if (searchInName || idBusinessInList) {
                    empresasFlag.push(business[b]);
                }
            }
            return empresasFlag
        } else {
            return business;
        }
    }

    function searchCategorias(): CategoriaModel[] {
        if (search !== '') {
            let categoriasFlag = [];
            for (let c in categorias) {
                let listCategoriasPesquisa: number[] = [];
                produtoSearch.forEach((item) => {
                    item.categorias_id.forEach((item) => {
                        listCategoriasPesquisa.push(item)
                    })
                })
                let idCategoriaInList = listCategoriasPesquisa.includes(categorias[c].id);
                let searchInName = categorias[c].nome.toLowerCase().includes(search.toLowerCase())
                if (searchInName || idCategoriaInList) {
                    categoriasFlag.push(categorias[c]);
                }
            }
            return categoriasFlag
        } else {
            return categorias;
        }
    }

    function getImageEmpresaFromId(id: number): string {
        const empresa = business.find((item) => item.id === id);
        if (empresa) {
            return empresa.new_logo ?? FALLBACK_IMAGE;
        }
        return '';
    }

    function getNomeEmpresaFromId(id: number): string {
        const empresa = business.find((item) => item.id === id);
        if (empresa) {
            return empresa.nome;
        }
        return '';
    }

    function getEmpresaHasRetirada(id: number): boolean {
        const empresa = business.find((item) => item.id === id);
        if (empresa) {
            return empresa.retirar_local === 1;
        }
        return false;
    }


    function btnOpenAddress() {
        const addressInString = localStorage.getItem('address_user') || '{}'
        const address = JSON.parse(addressInString) as EnderecoCliente
        return <Card elevation={3} sx={{
            width: '100%',
            height: size.x < 600 ? '100%' : 'auto',
        }}>
            <Button sx={{
                display: 'flex',
                flexDirection: 'row',
                paddingLeft: '10px',
                paddingRight: '10px',
                fontSize: '0.8rem',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
            }} onClick={() => {
                setOpenModalEnderecos(true);
                handleOpenModalEnderecos();
            }}>
                <LocationOnIcon sx={{
                    color: '#F9146B',
                }} />
                {
                    addressInString.length > 3 ?
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginLeft: '10px',
                        }}>
                            <Typography sx={{
                                fontSize: size.x > 700 ? '14px' : '12px',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 1,
                                textOverflow: 'ellipsis',
                                maxLines: 1,
                            }}>
                                {cidade!}, {address.bairro}
                            </Typography>
                        </div> :
                        <Typography sx={{
                            fontSize: size.x > 700 ? '14px' : '12px',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            textOverflow: 'ellipsis',
                            maxLines: 1,
                        }}>
                            {cidade!}
                        </Typography>
                }
                <div></div>
            </Button>
        </Card>;
    }

    function searchBar(): JSX.Element {
        return <Card sx={{
            borderRadius: '5px',
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingTop: '5px',
            paddingBottom: '10px',
            display: 'flex',
            width: '100%',
            marginLeft: size.x > 600 ? '8px' : '0px',
            marginTop: size.x > 600 ? '0px' : '10px',
        }} elevation={3}>
            <TextField
                placeholder='O que você vai querer?'
                sx={{
                    width: '90%',
                    // height: '40px',
                    borderRadius: '10px',
                    formControl: {
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        transform: 'translate(0, 24px) scale(1)',
                    },
                }}
                id="search-bar"
                className="text"
                onChange={async (text) => {
                    let pesquisa = text.target.value;
                    await getProdutsAPI(pesquisa);
                    setSearch(pesquisa);
                    if (text.target.value.length < 2) {
                        setProdutoSearch([]);
                        setSearch('');
                    }
                }}
                variant="standard"
                InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search sx={{
                                color: '#F9146B',
                                fontWeight: 'bold',
                            }} />
                        </InputAdornment>
                    )
                }}
                size="small"

            />
            {
                isLoadingSearch ? <CircularProgress
                    size={26}
                    sx={{
                        color: '#F9146B',
                    }} /> : null
            }
        </Card>;
    }

    function menuOptions(): JSX.Element {
        return <div style={{
            display: 'flex',
            marginLeft: '8px',
            marginTop: size.x > 600 ? '0px' : '10px',
        }}>
            <Card elevation={3} sx={{
                minWidth: '120px',
            }}>
                <Button
                    onClick={handleClick}
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                    {
                        userInString.length > 5 ?
                            <PersonIcon sx={{
                                color: '#F9146B',
                                fontSize: '2.2rem',
                            }} /> :
                            <Login sx={{
                                color: '#F9146B',
                                fontSize: '2rem',
                            }} />
                    }
                    {
                        userInString.length > 5 && <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: '4px',
                        }}>
                            <Typography sx={{
                                fontSize: '0.85rem',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 1,
                                textOverflow: 'ellipsis',
                                maxLines: 1,
                            }}>
                                {user.user.name.length > 6 ? user.user.name.substring(0, 6) + '...' : user.user.name}
                            </Typography>
                        </Box>
                    }
                </Button>
            </Card>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openMenu}
                onClose={handleCloseMenu}
                onClick={handleCloseMenu}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Box>
                    {
                        userInString.length > 3 ?
                            <>
                                <MenuItem onClick={() => {
                                    window.location.href = '/carrinho';
                                }}>
                                    <ListItemIcon>
                                        <ShoppingCartIcon fontSize="small" sx={{
                                            color: '#F9146B',
                                        }} />
                                    </ListItemIcon>
                                    Carrinho
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    const url = '/pedidos'
                                    window.location.href = url
                                }}>
                                    <ListItemIcon>
                                        <FastfoodIcon fontSize="small" sx={{
                                            color: '#F9146B',
                                        }} />
                                    </ListItemIcon>
                                    Pedidos
                                </MenuItem>
                            </> : null
                    }
                    {
                        userInString.length > 3 ?
                            <MenuItem onClick={() => {
                                localStorage.removeItem('user')
                                localStorage.removeItem('address_user')
                                window.location.href = '/login'
                                setCartAndSave([]);
                                Notificacao('Sucesso!', `Logout realizado com sucesso!`, 'success');
                            }}>
                                <ListItemIcon>
                                    <Logout fontSize="small" sx={{
                                        color: '#F9146B',
                                    }} />
                                </ListItemIcon>
                                Logout
                            </MenuItem> : <MenuItem onClick={() => {
                                window.location.href = '/login'
                            }}>
                                <ListItemIcon>
                                    <Login sx={{
                                        color: '#F9146B',
                                        fontSize: '2rem',
                                    }} />
                                </ListItemIcon>
                                Login
                            </MenuItem>
                    }
                </Box>
            </Menu>
        </div>;
    }

    function getEmpresaForDesktop(
        slugEmpresa: string,
        key: number,
        imagemUrl: string,
        empresa: EmpresaModel,
    ): JSX.Element {
        return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={key}>
                <Link to={`/empresa/${slugEmpresa}`}>
                    <Card sx={{
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 1,
                        '&:hover': {
                            boxShadow: '0px 0px 3px 3px #FAE600',
                        },
                    }}
                        elevation={4}
                    >
                        <CardMedia
                            component="img"
                            height="140"
                            image={imagemUrl.toString().length < 4 ?
                                empresa.logo ?
                                    (empresa.logo.length < 4 ? FALLBACK_IMAGE : empresa.logo) :
                                    FALLBACK_IMAGE :
                                imagemUrl
                            }
                            alt="green iguana"
                            onError={onMediaFallback}
                            sx={{
                                objectFit: 'contain',
                                backgroundColor: !empresa.new_logo && !empresa.logo ? 'linear-gradient(45deg, #2f3541 30%, #2f353e 90%)' : '#FAE600',
                            }}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="div" sx={{
                                fontSize: '0.9rem',
                                textAlign: 'justify',
                                maxLines: 2,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                fontWeight: 600,
                            }}>
                                {empresa.nome.trim()}
                            </Typography>
                            <Typography gutterBottom component="div" sx={{
                                fontSize: '0.8rem',
                            }}>
                                {empresa.aberta === 1 ? 'Aberta 🟢' : 'Fechada 🔴'}
                            </Typography>
                        </CardContent>
                    </Card>
                </Link>
            </Grid>
        );
    }

    function getEmpresaForMobile(
        key: number,
        empresa: EmpresaModel,
        imagemUrl: string,
    ): JSX.Element {
        return <Link to={`/empresa/${empresa.slug}`} key={key} style={{
            width: '100%',
            marginLeft: '6px',
            marginRight: '9px',
        }}>
            <Card sx={{
                width: '100%',
                padding: '5px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '8px',
                '&:hover': {
                    backgroundColor: '#f5f5f5',
                    boxShadow: '0px 0px 3px 3px #FAE600',
                },
                borderRadius: 2,
            }} elevation={4} >
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    <img src={imagemUrl.toString().length < 4 ?
                        empresa.logo ?
                            (empresa.logo.length < 4 ? FALLBACK_IMAGE : empresa.logo) :
                            FALLBACK_IMAGE :
                        imagemUrl
                    } alt="Imagem da empresa"
                        onError={onMediaFallback}
                        style={{
                            width: size.x > 700 ? '160px' : '120px',
                            height: size.x > 700 ? '160px' : '120px',
                            objectFit: empresa.logo == null ? 'contain' : 'cover',
                            marginBottom: size.x > 700 ? '10px' : '5px',
                            borderRadius: '5px',
                        }} />
                    <Box style={{
                        marginLeft: '10px',
                        justifyContent: 'space-between',
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <Box>
                            <Typography variant="h5" sx={{
                                fontSize: '0.9rem',
                                fontWeight: 'bold',
                            }}>{empresa.nome.trim()}</Typography>
                            <Typography sx={{
                                fontSize: '0.8rem',
                            }}>
                                {empresa.descricao === 'null' ? '(Sem descrição)' : empresa.descricao}
                            </Typography>
                        </Box>
                        <Typography variant="body1" sx={{
                            marginTop: '5px',
                            fontSize: '0.9rem',
                        }}>{empresa.aberta === 1 ? '🟢 Aberta' : '🔴 Fechada'}</Typography>
                    </Box>
                </div>
            </Card>
        </Link>;
    }

    let scroolRef = useRef<HTMLDivElement>(null);

    const [showScroolPosition, setShowScroolPosition] = useState(0);
    const [isLockScroolAdd, setIsLockScroolAdd] = useState(false);

    const [isScroolReverse, setIsScroolReverse] = useState(false);

    const [loopForCategorias, setLoopForCategorias] = useState(false);

    useEffect(() => {
        setTimeout(() => {

            if (searchCategorias().length > 0) {
                if (scroolRef.current) {
                    if (!isScroolReverse) {
                        scroolRef.current.scrollLeft += size.x > 700 ? 145 : 105;
                        setShowScroolPosition(scroolRef.current.scrollLeft);
                    } else {
                        scroolRef.current.scrollLeft -= size.x > 700 ? 125 : 85;
                        setShowScroolPosition(scroolRef.current.scrollLeft);
                    }

                    if (scroolRef.current.scrollLeft < 2) {
                        setIsScroolReverse(false);
                    }
                    else if (showScroolPosition === scroolRef.current.scrollLeft) {
                        setIsScroolReverse(true);
                    }
                }
            }

            setLoopForCategorias(!loopForCategorias)
            setIsLockScroolAdd(false)

        }, 5000);
    }, [loopForCategorias]);

    const scroolAdd = () => {
        if (scroolRef.current) {
            scroolRef.current.scrollLeft += size.x > 700 ? 125 : 85;
            if (showScroolPosition === scroolRef.current.scrollLeft) {
                setIsLockScroolAdd(true);
            } else {
                setShowScroolPosition(scroolRef.current.scrollLeft);
                setIsLockScroolAdd(false);
            }
        }
    }

    const scroolRemove = () => {
        if (scroolRef.current) {
            scroolRef.current.scrollLeft -= size.x > 700 ? 125 : 85;
            setShowScroolPosition(scroolRef.current.scrollLeft);
            setIsLockScroolAdd(false);
        }
    }

    function convertIDempresaToSlugEmpresa(idEmpresa: number): string {
        const empresa = business.find(empresa => empresa.id === idEmpresa);
        if (empresa) {
            return empresa.slug;
        }
        return '';
    }

    return (
        <div style={{
            marginTop: '95px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        }}>
            <ModalCustom
                open={openModalProdutoByPesquisa}
                title={nomeProdutoByPesquisa}
                onClose={handleCloseModalProdutoByPesquisa}
                actions={null}
                isMobile={size.x < 700}
                slug={null}
            >
                <ModalProdutos
                    idProduto={idProdutoByPesquisa}
                    empresaIsOpen={true}
                    onlyView={false}
                    produto={null}
                    stateFunction={
                        () => {
                            setOpenModalProdutoByPesquisa(false)
                        }
                    }
                    isRetirada={getEmpresaHasRetirada(idEmpresaByPesquisa)}
                    handleClose={handleCloseModalProdutoByPesquisa}
                    isOnCarrinho={false}
                    cidade_nome={cidade ?? ''}
                    isPromotion={false}
                    slugEmpresa={convertIDempresaToSlugEmpresa(idEmpresaByPesquisa)}
                />
            </ModalCustom>
            <ModalCustom
                open={openModalEnderecos}
                title='Endereços'
                onClose={handleCloseModalEnderecos}
                actions={null}
                isMobile={size.x < 700}
                slug={''}
            >
                <ModalEnderecos
                    onClose={handleCloseModalEnderecos}
                    onReload={() => setHandleOnReload(!handleOnReload)}
                    entity_id={entityId}
                    token={user.token}
                />
            </ModalCustom>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                width: size.x > 800 ? '85%' : size.x < 500 ? '98%' : '95%',
                marginBottom: '10px',
            }}>
                {size.x > 600 ? null :
                    <Box sx={{
                        height: '50px',
                        width: '100%',
                    }}>
                        {btnOpenAddress()}
                    </Box>
                }
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'space-between',
                    justifyContent: 'space-between',
                    textAlign: 'space-between',
                    width: '100%',
                }}>
                    {size.x > 600 ? btnOpenAddress() : null}
                    {searchBar()}
                    {menuOptions()}
                </Box>
            </Box>
            {
                getInfoShopingCart(size.x, cart, openInfoPerson, setOpenInfoPerson, imageUrl, setCartAndSave)
            }
            {
                produtoSearch.length > 0 && search.length > 2 ?
                    <Card style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        flexDirection: 'column',
                        padding: size.x ? '6px' : '2px',
                        width: size.x > 800 ? '85%' : size.x < 500 ? '98%' : '95%',
                    }}
                        elevation={0}
                    >
                        <Typography sx={{
                            marginTop: '15px',
                            fontSize: '1.3rem',
                            marginBottom: '20px',
                            alignItems: 'start',
                            textAlign: 'start',
                            marginLeft: '10px',
                        }}>
                            Produtos com o nome "{search}..."
                        </Typography>
                        <Grid container spacing={1} style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'space-between',
                            textAlign: 'center',
                            flexDirection: 'row',
                        }}>
                            {
                                produtoSearch.map((item, index) => (
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                        <Card sx={{
                                            marginBottom: '10px',
                                            marginTop: '10px',
                                            '&:hover': {
                                                boxShadow: '0px 0px 3px 3px #FAE600',
                                            },
                                            height: size.x > 700 ? '260px' : '245px',
                                            alignItems: 'start',
                                            justifyContent: 'start',
                                            textAlign: 'start',
                                        }} onClick={() => {
                                            setIdProdutoByPesquisa(item.id);
                                            setIdEmpresaByPesquisa(item.empresas_id);
                                            setNomeProdutoByPesquisa(item.nome);
                                            setOpenModalProdutoByPesquisa(true)
                                            handleOpenModalProdutoByPesquisa();
                                        }} elevation={5}>
                                            <CardActionArea sx={{
                                                '&:hover': {
                                                    transition: 'opacity 200ms',
                                                },
                                                alignItems: 'start',
                                                justifyContent: 'start',
                                                textAlign: 'start',
                                            }}>
                                                <CardMedia
                                                    component="img"
                                                    image={getImageEmpresaFromId(item.empresas_id).length < 5 ? FALLBACK_IMAGE : getImageEmpresaFromId(item.empresas_id)}
                                                    alt="green iguana"
                                                    onError={onMediaFallback}
                                                    height={size.x < 630 ? '110px' : size.x < 790 ? '115px' : '120px'}
                                                    sx={{
                                                        objectFit: 'contain',
                                                        backgroundColor: getImageEmpresaFromId(item.empresas_id).length < 5 ? 'linear-gradient(45deg, #2f3541 30%, #2f353e 90%)' : '#FAE600',
                                                    }}
                                                />
                                                <CardContent>
                                                    <div style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'space-between',
                                                        flexDirection: 'row',
                                                    }}>
                                                        <Typography gutterBottom variant="h5" component="div" sx={{
                                                            fontSize: size.x > 700 ? '16px' : '14px',
                                                            maxLines: 2,
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                        }}>
                                                            {item.nome.trim()}
                                                        </Typography>

                                                    </div>
                                                    <Typography variant="body2" color="text.secondary" sx={{
                                                        textAlign: 'justify',
                                                        maxLines: 4,
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        fontSize: size.x > 700 ? '14px' : '12px',
                                                    }}>
                                                        {item.descricao == null || item.descricao.length < 1 ? 'Sem descrição' : item.descricao}
                                                    </Typography>
                                                </CardContent>
                                                <Box sx={{
                                                    textAlign: 'space-between',
                                                    marginRight: '10px',
                                                    marginLeft: '10px',
                                                }}>
                                                    <Typography sx={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        fontSize: size.x > 700 ? '14px' : '12px',
                                                    }}>
                                                        {getNomeEmpresaFromId(item.empresas_id).trim()}
                                                    </Typography>
                                                    <Typography sx={{
                                                        fontSize: '16px',
                                                        color: '#F9146B',
                                                        fontWeight: 'bold',
                                                    }}>
                                                        {item.preco.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                    </Typography>
                                                </Box>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                ))}
                        </Grid>
                    </Card> : <></>
            }
            {
                isLoadingCategoria ? <div style={{
                    textAlign: 'center',
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}>
                        <Typography gutterBottom component="h2" style={{
                            textAlign: 'center',
                            marginTop: '20px',
                            marginBottom: '20px',
                            fontSize: '1.3rem',
                        }}>
                            Carregando categorias...
                        </Typography>
                        <CircularProgress sx={{
                            color: '#F9146B',
                        }} />
                    </Box>
                </div> : errorCategoria ?
                    <Box sx={{
                        width: '90%',
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                    }}>
                        <Typography gutterBottom component="h2" style={{
                            textAlign: 'center',
                            width: '100%',
                            margin: size.x > 790 ? '4%' : '1%',
                            marginTop: '20px',
                            marginBottom: '20px',
                            fontSize: '1.3rem',
                        }}>
                            Erro ao carregar categorias
                            <br></br>
                            <Button variant="contained" onClick={() => getCategoriasCity()}>Tentar novamente</Button>
                        </Typography>
                    </Box>
                    :
                    <Card style={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '12px',
                        width: size.x > 800 ? '85%' : size.x < 500 ? '98%' : '95%',
                        position: 'relative',
                    }} elevation={0}>
                        {searchCategorias().length > 0 && <Typography sx={{
                            marginTop: '5px',
                            fontSize: size.x > 700 ? '23px' : '19px',
                            marginBottom: '10px',
                            alignItems: 'start',
                            textAlign: 'start',
                        }}>
                            Categorias
                        </Typography>}
                        <Box sx={{
                            position: 'absolute',
                            left: 0,
                            marginTop: 5,
                            height: size.x > 700 ? 160 : 120,
                            width: size.x > 700 ? '50px' : '40px',
                            background: "linear-gradient(-90deg, rgba(255,255,255,0.17130602240896353) 43%, rgba(255,255,255,0.5438550420168067) 56%, rgba(255,255,255,0.8463760504201681) 71%)",
                            zIndex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                        }}>
                            {
                                showScroolPosition > 0 ?
                                    <KeyboardArrowLeftIcon
                                        onClick={scroolRemove}
                                        sx={{
                                            width: size.x > 700 ? 45 : 35,
                                            height: size.x > 700 ? 45 : 35,
                                            backgroundColor: 'white',
                                            borderRadius: '50%',
                                            position: 'relative',
                                            top: 0,
                                            bottom: 0,
                                            color: '#F9146B',
                                            cursor: 'pointer',
                                        }} /> : null
                            }
                        </Box>
                        <Box sx={{
                            position: 'absolute',
                            right: 0,
                            marginTop: 5,
                            height: size.x > 700 ? 160 : 120,
                            width: size.x > 700 ? '50px' : '40px',
                            background: "linear-gradient(90deg, rgba(255,255,255,0.17130602240896353) 43%, rgba(255,255,255,0.5438550420168067) 56%, rgba(255,255,255,0.8463760504201681) 71%)",
                            zIndex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                        }}>
                            {
                                !isLockScroolAdd ?
                                    <KeyboardArrowRightIcon
                                        onClick={scroolAdd}
                                        sx={{
                                            width: size.x > 700 ? 45 : 35,
                                            height: size.x > 700 ? 45 : 35,
                                            backgroundColor: 'white',
                                            borderRadius: '50%',
                                            position: 'relative',
                                            top: 0,
                                            bottom: 0,
                                            color: '#F9146B',
                                            cursor: 'pointer',
                                        }} /> : null
                            }
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'start',
                            flex: 1,
                            overflowX: 'scroll',
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                            scrollbarWidth: 'none',
                            msOverflowStyle: 'none',
                        }} ref={scroolRef}>
                            {
                                searchCategorias().map((categoria, index) => {
                                    return <Link to={`/empresas/${cidade}/${categoria.id}`} key={index}>
                                        <CardActionArea sx={{
                                            margin: 0,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            width: size.x > 700 ? 125 : 85,
                                            marginRight: '10px',
                                            padding: '5px',
                                        }}>
                                            <Card sx={{
                                                height: size.x > 700 ? 110 : 70,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                '&:hover': {
                                                    boxShadow: '0px 0px 3px 3px #FAE600',
                                                    transition: 'opacity 200ms',
                                                },
                                                borderRadius: '90px',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                textAlign: 'center',
                                            }} elevation={2}>
                                                <CardMedia
                                                    component="img"
                                                    image={categoria.image ? categoria.image : FALLBACK_IMAGE}
                                                    alt={categoria.nome}
                                                    onError={onMediaFallback}
                                                    sx={{
                                                        backgroundColor: '#FFF',
                                                        objectFit: 'contains',
                                                    }}
                                                />
                                            </Card>
                                            <Typography gutterBottom sx={{
                                                display: '-webkit-box',
                                                overflow: 'hidden',
                                                WebkitBoxOrient: 'vertical',
                                                WebkitLineClamp: 1,
                                                textOverflow: 'ellipsis',
                                                maxLines: 1,
                                                fontSize: '0.75rem',
                                                marginTop: '15px',
                                                color: '#000',
                                                fontWeight: 500,
                                            }}>
                                                {categoria.nome}
                                            </Typography>
                                        </CardActionArea>
                                    </Link>

                                })
                            }
                        </Box>
                    </Card>
            }
            {
                isLoadingBanner ? <div style={{
                    textAlign: 'center',
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}>
                        <Typography gutterBottom component="h2" style={{
                            textAlign: 'center',
                            marginTop: '30px',
                            marginBottom: '20px',
                            fontSize: '1.3rem',
                        }}>
                            Carregando banners...
                        </Typography>
                        <CircularProgress sx={{
                            color: '#F9146B',
                        }} />
                    </Box>
                </div> : errorBanner ? <div style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                }}>
                    {
                        <Typography sx={{
                            marginTop: '10px',
                            marginBottom: '6px',
                            fontSize: '1.3rem',
                        }}>
                            Erro ao carregar banner
                        </Typography>
                    }
                </div> : <Carrocel
                    bannerFirst={bannerFirst!}
                    banners={banners}
                    handleCloseModalPromocoes={handleCloseModalPromocoes}
                    handleOpenModalPromocoes={handleOpenModalPromocoes}
                    openModalPromocoes={openModalPromocoes}
                    setBannersFirst={setBannersFirst}
                    setOpenModalPromocoes={setOpenModalPromocoes}
                    size={size.x}
                    empresaHasRetirada={false}
                    cidade_nome={cidade ?? ''}
                    convertIDempresaToSlug={convertIDempresaToSlugEmpresa}
                />
            }
            {loading ? <div style={{
                textAlign: 'center',
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}>
                    <Typography gutterBottom component="h2" style={{
                        textAlign: 'center',
                        marginTop: '30px',
                        marginBottom: '20px',
                        fontSize: '1.3rem',
                    }}>
                        Carregando empresas...
                    </Typography>
                    <CircularProgress sx={{
                        color: '#F9146B',
                    }} />
                </Box>
            </div> :
                error ?
                    <div style={{
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        marginTop: '20px',
                        marginBottom: '20px',
                    }}>
                        <Box sx={{ width: '90%' }}>
                            <Typography gutterBottom component="h2" style={{
                                textAlign: 'center',
                                width: '100%',
                                marginTop: '10px',
                                marginBottom: '20px',
                                fontSize: '1.3rem',
                            }}>
                                Erro ao carregar empresas
                            </Typography>
                            <Button variant="contained" onClick={() => getEmpresas()}>Tentar novamente</Button>
                        </Box>
                    </div> :
                    <Card style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxAlign: 'center',
                        flexDirection: 'column',
                    }} elevation={0}>
                        <Typography sx={{
                            width: size.x > 800 ? '85%' : size.x < 500 ? '98%' : '95%',
                            fontSize: size.x > 700 ? '23px' : '19px',
                            marginBottom: '30px',
                            alignItems: 'start',
                            textAlign: 'start',
                        }}>
                            Empresas
                        </Typography>
                        <Grid container spacing={1} sx={{
                            width: size.x > 800 ? '85%' : size.x < 500 ? '100%' : '95%',
                            marginBottom: '10px',
                        }}>
                            {
                                searchEmpresas().map((empresa, index) => {
                                    return size.x > 600 ? getEmpresaForDesktop(
                                        empresa.slug,
                                        index,
                                        empresa.new_logo ?? '',
                                        empresa,
                                    ) : getEmpresaForMobile(
                                        index,
                                        empresa,
                                        empresa.new_logo ?? '',
                                    )
                                })
                            }
                        </Grid>
                    </Card>
            }
        </div>
    )
}

export default EmpresasFromCity
