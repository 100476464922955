import { Logout } from '@mui/icons-material';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LoginIcon from '@mui/icons-material/Login';
import Person from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Box, Button, Card, CardMedia, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

import Notificacao from '../../../../components/notification';
import BACKGROUND_WALLPAPER from '../../business-icons.png';
import { Root as UserModel } from '../../model/user.model';
import FALLBACK_IMAGE from '../../onerror.png';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function getInfoShopingCart(
    size: number,
    cart: any,
    open: boolean,
    setOpen: Function,
    image: string,
    setCartAndSave: Function,
) {


    const onMediaFallback = (event: any) => event.target.src = FALLBACK_IMAGE;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const styleCard = {
        backgroundColor: '#FAE600',
        padding: '10px',
        borderRadius: '40px',
        height: size > 700 ? 50 : 40,
        width: size > 700 ? 50 : 40,
        alignItems: 'center',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
        boxShadow: '1px 2px #ababab',
    }

    const userInString = localStorage.getItem('user') || '{}'
    const user = JSON.parse(userInString) as UserModel

    return (
        <Box sx={{
            position: 'fixed',
            right: size > 700 ? 20 : 10,
            bottom: size > 700 ? 20 : 15,
            zIndex: 100,
            display: 'flex',
            flexDirection: 'column',
        }}>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                fullWidth={true}
            >
                {
                    userInString.length > 5 ? <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 2,
                    }}>
                        <CardMedia
                            component="img"
                            image={image}
                            alt="green iguana"
                            onError={onMediaFallback}
                            sx={{
                                objectFit: 'cover',
                                backgroundImage: `url(${BACKGROUND_WALLPAPER})`,
                                backgroundColor: '#FAE600',
                                height: size > 700 ? 180 : 140,
                                width: size > 700 ? 180 : 140,
                                borderRadius: '50%',
                                boxShadow: '1px 2px #ababab',
                            }}
                        />

                        <DialogTitle sx={{
                            marginTop: 2,
                            marginBottom: 0,
                            padding: 0,
                        }}>{user ? user.user.name : 'Faça login'}</DialogTitle>
                        <DialogContentText id="alert-dialog-slide-description" sx={{
                            margin: 0,
                            marginTop: 0,
                            marginBottom: 1,
                        }}>
                            {user && user.user.email}
                        </DialogContentText>
                        <Button variant="outlined" sx={{
                            width: 170,
                            color: '#F9146B',
                            borderColor: '#F9146B',
                            marginTop: 2,
                            '&:hover': {
                                backgroundColor: '#F9146B',
                                color: '#FFFF',
                            },
                            display: 'flex',
                            alignItems: 'space-between',
                            justifyContent: 'space-between',
                        }} onClick={() => {
                            const url = '/pedidos'
                            window.location.href = url
                        }}>
                            <FastfoodIcon />
                            <Typography>
                                Pedidos
                            </Typography>
                        </Button>
                        <Button variant="outlined" sx={{
                            width: 170,
                            color: '#F9146B',
                            borderColor: '#F9146B',
                            marginTop: 2,
                            '&:hover': {
                                backgroundColor: '#F9146B',
                                color: '#FFFF',
                            },
                            display: 'flex',
                            alignItems: 'space-between',
                            justifyContent: 'space-between',
                        }} onClick={() => {
                            window.location.href = '/carrinho';
                        }}>
                            <ShoppingCartIcon />
                            <Typography>
                                Carrinho
                            </Typography>
                        </Button>
                        <Button variant="outlined" sx={{
                            width: 170,
                            color: '#F9146B',
                            borderColor: '#F9146B',
                            marginTop: 2,
                            '&:hover': {
                                backgroundColor: '#F9146B',
                                color: '#FFFF',
                            },
                            display: 'flex',
                            alignItems: 'space-between',
                            justifyContent: 'space-between',
                        }} onClick={() => {
                            localStorage.removeItem('user')
                            localStorage.removeItem('address_user')
                            window.location.href = '/login'
                            setCartAndSave([]);
                            Notificacao('Sucesso!', `Logout realizado com sucesso!`, 'success');
                        }}>
                            <Logout />
                            <Typography>
                                Logout
                            </Typography>
                        </Button>
                    </Box> : <Box sx={{
                        padding: 2,
                    }}>
                        <DialogTitle sx={{
                            padding: 0,
                            margin: 0,
                        }}>{"Realizar login"}</DialogTitle>
                        <DialogContent sx={{
                            padding: 0,
                            margin: 0,
                        }}>
                            <DialogContentText id="alert-dialog-slide-description" sx={{
                                padding: 0,
                                margin: 0,
                            }}>
                                Faça login para fazer pedidos e utilizar o máximo de nossa plataforma!
                            </DialogContentText>
                        </DialogContent>
                        <Box sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            marginTop: 4,
                        }}>
                            <Button sx={{
                                color: '#F9146B',
                                border: '1px solid #F9146B',
                                width: '80%',
                            }} onClick={() => {
                                const url = '/login'
                                window.location.href = url
                            }}>
                                Fazer login
                            </Button>
                            <Button sx={{
                                color: '#F9146B',
                                border: '1px solid #F9146B',
                                width: '80%',
                                marginTop: 2,
                            }} onClick={() => {
                                setCartAndSave([]);
                                Notificacao('Sucesso!', `Carrinho limpo com sucesso!`, 'success');
                            }}>
                                Limpar carrinho
                            </Button>
                        </Box>
                    </Box>
                }
                <DialogActions sx={{
                    margin: 1,
                    marginTop: userInString.length > 5 ? 2 : 1,
                }}>
                    <Button onClick={handleClose} sx={{
                        color: '#F9146B',
                    }}>Fechar</Button>
                </DialogActions>
            </Dialog>
            {
                cart.length > 0 && <Card sx={styleCard} elevation={4} onClick={() => {
                    const url = `/carrinho`
                    window.location.href = url
                }}>
                    <Box sx={{
                        display: 'flex',
                        position: 'relative',
                    }}>
                        <ShoppingCartIcon sx={{
                            fontSize: size > 700 ? '1.9rem' : '1.8rem',
                            color: '#000',
                            position: 'relative',
                            left: 5,
                            right: 0,
                        }}
                        />
                        <Box sx={{
                            fontSize: size > 700 ? '1rem' : '0.9rem',
                            color: '#FFFF',
                            backgroundColor: 'red',
                            borderRadius: '50%',
                            width: size > 700 ? '20px' : '17px',
                            height: size > 700 ? '20px' : '17px',
                            textAlign: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <Typography sx={{
                                margin: 0,
                                padding: 0,
                                fontSize: '0.75rem',
                                fontWeight: 'bold',
                            }}>
                                {cart.length}
                            </Typography>
                        </Box>
                    </Box>
                </Card>
            }
            <Card sx={{
                ...styleCard,
                marginTop: cart.length > 0 ? 1.4 : 0,
            }} elevation={4} onClick={handleClickOpen}>
                {
                    userInString.length > 5 ? <Person sx={{
                        fontSize: '2.1rem',
                        color: '#000',
                        alignItems: 'center',
                        textAlign: 'center',
                        justifyContent: 'center',
                    }}
                    /> : <LoginIcon sx={{
                            fontSize: '2rem',
                            color: '#000',
                            alignItems: 'center',
                            textAlign: 'center',
                            justifyContent: 'center',
                        }}
                    />
                }
            </Card>
        </Box>
    );
}