import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Button, Card, CircularProgress, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import styles from '../../sobre/style.module.css'
import { useCart } from '../context/cartcontext';
import getCategorias from '../controllers/getEmpresasByCategoria';
import { getImageProfile } from '../controllers/getImageProfile';
import { Root as EmpresasModel } from '../model/empresas.model';
import { Root as UserModel } from '../model/user.model';
import FALLBACK_IMAGE from '../onerror.png';
import { getInfoShopingCart } from './widgets/shopingCartFloating';

const CategoriaPage = () => {

    const [empresas, setEmpresas] = useState<EmpresasModel[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const onMediaFallback = (event: any) => event.target.src = FALLBACK_IMAGE;

    const [imageUrl, setImageUrl] = useState(FALLBACK_IMAGE)

    const { cart, setCartAndSave } = useCart();

    const [openInfoPerson, setOpenInfoPerson] = useState(false);

    const [size, setSize] = useState({
        x: window.innerWidth,
        y: window.innerHeight
    });
    const updateSize = () =>
        setSize({
            x: window.innerWidth,
            y: window.innerHeight
        });
    useEffect(() => (window.onresize = updateSize), []);

    useEffect(() => {
        getImage()
        getCategoriasEmpresas();
    }, []);

    const { cidade, idCategoria } = useParams()

    async function getImage() {
        const userInString = await localStorage.getItem('user') || '{}'
        if (userInString.length < 5) return
        const user = JSON.parse(userInString) as UserModel
        const image = await getImageProfile(user.user.id)
        if (image) {
            setImageUrl(image)
        }
    }

    async function getCategoriasEmpresas(): Promise<void> {
        setLoading(true);
        if (cidade && idCategoria) {
            const id = parseInt(idCategoria);
            const categ = await getCategorias(cidade, id);
            if (categ) {
                setError(false);
                setEmpresas(categ);
            } else {
                setError(true);
            }
        }
        setLoading(false);
    }

    function btnVerMais(slugEmpresa: string) {
        return <Button variant="contained" component={Link} style={{
            backgroundColor: '#F9146B',
            marginRight: size.x > 700 ? '20px' : '0px',
            width: '150px',
            fontSize: '0.9rem',
        }} to={`/empresa/${slugEmpresa}`}>Ver empresa</Button>;
    }

    return (
        <div>
            <Grid className={styles.body}>
                <div style={{
                    padding: `0% ${size.x > 700 ? '10%' : '0%'}`,
                }}>
                    {
                        loading ? <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                        }}>
                            <Typography gutterBottom component="h2" style={{
                                textAlign: 'center',
                                marginTop: '10px',
                                marginBottom: '20px',
                                fontSize: '1.3rem',
                            }}>
                                Carregando empresas...
                            </Typography>
                            <CircularProgress sx={{
                                color: '#F9146B',
                            }} />
                        </Box> :
                            error ?
                                <div>
                                    <Typography variant="h4">Erro ao carregar as empresas</Typography>
                                    <br></br>
                                    <Button variant="contained" onClick={getCategoriasEmpresas}>Tentar novamente</Button>
                                </div> :
                                empresas.length === 0 ? <Typography variant="h4">Nenhuma empresa encontrada</Typography> :
                                    <Box>
                                        {
                                            getInfoShopingCart(size.x, cart, openInfoPerson, setOpenInfoPerson, imageUrl, setCartAndSave)
                                        }
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            color: '#F9146B',
                                            border: '1px solid #F9146B',
                                            padding: '10px',
                                            marginLeft: '5px',
                                            marginRight: '5px',
                                            borderRadius: '5px',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            textAlign: 'center',
                                            marginBottom: '20px',
                                        }}>
                                            <Button variant="contained"
                                                sx={{
                                                    backgroundColor: '#F9146B',
                                                    marginRight: size.x > 700 ? '20px' : '0px',
                                                    width: '150px',
                                                    fontSize: '0.8rem',
                                                }} onClick={() => {
                                                    window.history.back();
                                                }}>
                                                <ArrowBackIosIcon />
                                                Voltar
                                            </Button>

                                            <Typography>
                                                {empresas.length} empresas
                                            </Typography>
                                        </Box>
                                        {
                                            empresas.map((empresa, index) => (
                                                <Link to={`/empresa/${empresa.slug}`} key={index}>
                                                    <Card key={index} sx={{
                                                        padding: '5px',
                                                        margin: '20px 5px',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        '&:hover': {
                                                            backgroundColor: '#f5f5f5',
                                                            boxShadow: '0px 0px 3px 3px #FAE600',
                                                        }
                                                    }} elevation={3} >
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                        }}>
                                                            <div style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: 'start',
                                                                textAlign: 'start',
                                                                alignItems: 'center',
                                                            }}>
                                                                <img src={empresa.new_logo == null ? FALLBACK_IMAGE : empresa.new_logo} alt="Imagem da empresa"
                                                                    onError={onMediaFallback}
                                                                    style={{
                                                                        width: size.x > 700 ? '160px' : '120px',
                                                                        height: size.x > 700 ? '160px' : '120px',
                                                                        objectFit: empresa.logo == null ? 'contain' : 'cover',
                                                                        marginBottom: size.x > 700 ? '10px' : '5px',
                                                                    }} />
                                                            </div>
                                                            <Box style={{
                                                                marginLeft: '10px',
                                                                justifyContent: 'space-between',
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                            }}>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'start',
                                                                    alignItems: 'start',
                                                                }}>
                                                                    <Typography variant="h5" sx={{
                                                                        fontSize: '0.9rem',
                                                                        fontWeight: 'bold',
                                                                    }}>{empresa.nome}</Typography>
                                                                    <Typography sx={{
                                                                        fontSize: '0.8rem',
                                                                    }}>
                                                                        {empresa.descricao === 'null' ? '(Sem descrição)' : empresa.descricao}
                                                                    </Typography>
                                                                    <Typography variant="body1" sx={{
                                                                        marginTop: '5px',
                                                                        fontSize: '0.8rem',
                                                                    }}>{empresa.aberta === 1 ? '🟢 Aberta' : '🔴 Fechada'}</Typography>
                                                                </div>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                }}> 
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        flexWrap: 'wrap',
                                                                        marginTop: '3px',
                                                                    }}>
                                                                        <Typography sx={{
                                                                            fontSize: '0.8rem',
                                                                        }}>
                                                                            Tempo médio
                                                                        </Typography>
                                                                        <Typography sx={{
                                                                            marginRight: '5px',
                                                                            fontSize: '0.8rem',
                                                                            fontWeight: 'bold',
                                                                        }}>:  {empresa.tempo_medio} min</Typography>
                                                                    </div>
                                                                </div>
                                                            </Box>
                                                        </div>
                                                        {
                                                            size.x > 700 ?
                                                                btnVerMais(empresa.slug)
                                                                : null
                                                        }
                                                    </Card>
                                                </Link>
                                            ))
                                        }
                                    </Box>
                    }
                </div>
            </Grid>
            <div style={{
                height: '80px',
            }}></div>
        </div>
    )
}

export default CategoriaPage
