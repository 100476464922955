import CheckIcon from '@mui/icons-material/Check';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import TimerIcon from '@mui/icons-material/Timer';
import { Box, Button, Card, CardMedia, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ModalCustom } from '../../../components/modal';
import Notificacao from '../../../components/notification';
import styles from '../../sobre/style.module.css'
import { useCart } from '../context/cartcontext';
import { cancelarPedido, getPedidosAbertos, getPedidosCancelados, getPedidosFinalizados } from '../controllers/CRUDPedidos';
import { getImageProfile } from '../controllers/getImageProfile'
import { Historico, Root as Pedido } from '../model/pedido.model';
import { Root as UserModel } from '../model/user.model';
import FALLBACK_IMAGE from '../onerror.png';
import ModalDetalhesPedido from './modal/detalhesPedido/modalDetalhesPedidos';
import ModalMakePix from './modal/modalmakepix/makepix';
import { getInfoShopingCart } from './widgets/shopingCartFloating'
import InfoIcon from '@mui/icons-material/Info';
import ModalInfoCode from './modal/infoSecurityCode/infoCode';

const PedidosPage = () => {

    const [itemSelecionado, setItemSelecionado] = useState(0)
    const [idDoCliente, setIdDoCliente] = useState<number>(-1)
    const [pedidos, setPedidos] = useState<Pedido[] | null>([])
    const [isLoading, setIsLoading] = useState(false)
    const [openModalPedidos, setOpenModalPedidos] = useState(false)
    const handleOpenModalPedidos = () => setOpenModalPedidos(true);
    const handleCloseModalPedidos = () => setOpenModalPedidos(false);

    const [openModalPix, setOpenModalPix] = useState(false)
    const handleCloseModalPix = () => setOpenModalPix(false);

    const [pedidoSelecionado, setPedidoSelecionado] = useState<Pedido | null>(null)
    const { cart, setCartAndSave } = useCart();

    const [openDialog, setOpenDialog] = useState(false);
    const [idPedido, setIdPedido] = useState(0)
    const [openInfoPerson, setOpenInfoPerson] = useState(false);
    const [openModalInfoCode, setOpenModalInfoCode] = useState(false);

    const [imageUrl, setImageUrl] = useState(FALLBACK_IMAGE)

    const userInString = localStorage.getItem('user') || '{}'
    const user = JSON.parse(userInString) as UserModel


    async function getImage() {
        if (userInString.length < 5) return
        const image = await getImageProfile(user.user.id)
        if (image) {
            setImageUrl(image)
        }
    }

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const [size, setSize] = useState({
        x: window.innerWidth,
        y: window.innerHeight
    });
    const updateSize = () =>
        setSize({
            x: window.innerWidth,
            y: window.innerHeight
        });
    useEffect(() => (window.onresize = updateSize), []);

    async function getPedidosAbertosFunction(clientID: number): Promise<void> {
        setIsLoading(true)
        try {
            const res = await getPedidosAbertos(clientID)
            setPedidos(res)
        } catch (error) {
            setPedidos(null)
        }
        setIsLoading(false)
    }

    async function getPedidosFinalizadosFunction(clientID: number): Promise<void> {
        setIsLoading(true)
        try {
            const res = await getPedidosFinalizados(clientID)
            setPedidos(res)
        } catch (error) {
            setPedidos(null)
        }
        setIsLoading(false)
    }

    async function getPedidosCanceladosFunction(clientID: number): Promise<void> {
        setIsLoading(true)
        try {
            const res = await getPedidosCancelados(clientID)
            setPedidos(res)
        } catch (error) {
            setPedidos(null)
        }
        setIsLoading(false)
    }

    async function getPedidosFunction(): Promise<void> {
        if (user.user.entity_id === -1) {
            return
        }
        await getImage()
        setIdDoCliente(user.user.entity_id)
        if (itemSelecionado === 0) {
            await getPedidosAbertosFunction(user.user.entity_id)
        }
        else if (itemSelecionado === 1) {
            await getPedidosFinalizadosFunction(user.user.entity_id)
        }
        else if (itemSelecionado === 2) {
            await getPedidosCanceladosFunction(user.user.entity_id)
        }
    }

    useEffect(() => {
        getPedidosFunction()
    }, [
        itemSelecionado,
    ])

    function getObjJSXforStatusPedido(
        statusPedido: Historico, 
        isCancel: boolean, 
        qrcode: string | null, 
        status: number,
        hash_cliente: string
    ): JSX.Element {

        function rowComponentsJSX(mensagem: string, dataRow: string, iconJSX: JSX.Element, isWidth: boolean): JSX.Element {
            return <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
            }}>
                <Typography variant="h6" component="p" sx={{
                    fontSize: '0.8rem',
                    maxWidth: '100px',
                }} >
                    {mensagem}
                </Typography>
                {iconJSX}
                <Typography sx={{
                    marginTop: '15px',
                    fontSize: '0.8rem',
                    width: '50px',
                    height: '10px',
                }}>
                    {dataRow}
                </Typography>
            </Box>
        }

        return <div style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
            width: '95%',
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
            }}>
                {rowComponentsJSX(statusPedido.aceito === null && qrcode == null ? "Aguardando a empresa aceitar" :
                    status === -1 && qrcode != null ? "Aguardando pagamento"
                        : "Preparando", statusPedido.aceito ?? '', <CheckIcon sx={{
                            fontSize: '40px',
                            color: statusPedido.aceito === null ? '#c4c4c4' : '#000',
                            marginTop: '10px',
                        }} />,
                    statusPedido.realizado ? true : false,
                )}
                {rowComponentsJSX('A recolher', statusPedido.recolher ?? '', <TimerIcon sx={{
                    fontSize: '40px',
                    color: statusPedido.recolher === null ? '#c4c4c4' : '#000',
                    marginTop: '10px',
                }} />,
                    false,
                )}
                {rowComponentsJSX('Enviando', statusPedido.enviando ?? '', <DeliveryDiningIcon sx={{
                    fontSize: '40px',
                    color: statusPedido.enviando === null ? '#c4c4c4' : '#000',
                    marginTop: '10px',
                }} />,
                    false,
                )}
                {rowComponentsJSX('Concluido', statusPedido.entregue ?? '', <InsertEmoticonIcon sx={{
                    fontSize: '40px',
                    color: statusPedido.entregue === null ? '#c4c4c4' : '#000',
                    marginTop: '10px',
                }} />,
                    false,
                )}

            </div>
            <Box height={35}/>
            <Box sx={{
                display: 'flex',
                padding: '15px',
                backgroundColor: '#ffecb3',
                borderRadius: '5px',
                justifyContent: 'space-between'
            }}>
                <Box display='flex'>
                    <Typography marginRight={1}>
                        Código de segurança:
                    </Typography>
                    <Typography fontWeight="bold">
                        {hash_cliente}
                    </Typography>
                </Box>
                <InfoIcon 
                onClick={() => setOpenModalInfoCode(true)}
                sx={{
                    cursor: 'pointer',
                    '&:hover': {
                        color: '#F9146B',
                    }
                }}/>
            </Box>
            {
                isCancel ? <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '5px',
                }}>
                    <Typography variant="h6" component="p" sx={{
                        fontSize: '1rem',
                    }}>
                        Pedido cancelado
                    </Typography>
                </Box> : <></>
            }
        </div>
    }


    function rowComponentJSXForValuePedido(pedidoFlag: Pedido): JSX.Element {
        function rowForComponent(mensagem: string, valor: string): JSX.Element {
            return <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}>
                <div>
                    <Typography sx={{
                        fontSize: size.x > 700 ? '17px' : '14px',
                    }}>
                        {mensagem}
                    </Typography>
                </div>
                <div>
                    <Typography sx={{
                        fontSize: size.x > 700 ? '17px' : '14px',
                    }}>
                        {valor}
                    </Typography>
                </div>
            </div>
        }

        return <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '95%',
        }}>
            {rowForComponent('Taxa de entrega:', pedidoFlag.taxaEntrega.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))}
            {rowForComponent('Desconto:', pedidoFlag.desconto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))}
            {rowForComponent('Valor do total (produto)', pedidoFlag.valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))}
            {rowForComponent('Valor do total (produto + entrega)', pedidoFlag.valorFinal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))}

        </div>
    }

    function btnIrParaLoja(slug: string): JSX.Element {
        return <Link to={`/empresa/${slug}`}>
            <Button sx={{
                color: '#F9146B',
                fontWeight: 'bold',
                fontSize: size.x > 700 ? '16px' : '12px',
            }}>
                Ir para a loja
            </Button>
        </Link>
    }

    return (
        <div>
            <Grid className={styles.body}>
            <ModalCustom
                open={openModalInfoCode}
                title='Atenção'
                onClose={() => setOpenModalInfoCode(false)}
                actions={null}
                isMobile={false}
                slug={''}
            >
                <ModalInfoCode onClose={() => setOpenModalInfoCode(false)} />
            </ModalCustom>
                <Dialog
                    open={openDialog}
                    keepMounted
                    onClose={handleCloseDialog}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Deseja cancelar o pedido?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Caso a empresa já tenha aceitado seu pedido não será possível cancelar.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Não</Button>
                        <Button onClick={async () => {
                            setIsLoading(true);
                            const result = await cancelarPedido(idPedido, user.token);
                            if (result) {
                                Notificacao('Sucesso!', `Pedido cancelado com sucesso`, 'success');
                                if (pedidos) {
                                    for (let i = 0; i < pedidos.length; i++) {
                                        if (pedidos[i].id === idPedido) {
                                            setPedidos(pedidos.filter((pedido) => pedido.id !== idPedido));
                                            break;
                                        }
                                    }
                                }
                            } else {
                                Notificacao('Atenção', `Não foi possível cancelar o pedido. Recarregue a página e verifique se a empresa está preparando.`, 'danger');
                            }
                            handleCloseDialog();
                            setIsLoading(false);
                        }}>Sim</Button>
                    </DialogActions>
                </Dialog>
                {
                    getInfoShopingCart(size.x, cart, openInfoPerson, setOpenInfoPerson, imageUrl, setCartAndSave)
                }
                {
                    idDoCliente !== -1 ?
                        <div style={{
                            padding: '0% 0%',
                            margin: '0% 0%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}>
                            <Typography sx={{
                                fontSize: '1.2rem',
                                fontWeight: 'bold',
                                marginBottom: '20px',
                            }}>
                                Página de pedidos
                            </Typography>
                            <ModalCustom
                                open={openModalPedidos}
                                title='Detalhes do pedido'
                                onClose={handleCloseModalPedidos}
                                actions={null}
                                isMobile={size.x < 700}
                                slug={''}
                            >
                                <ModalDetalhesPedido pedido={pedidoSelecionado!} onClose={handleCloseModalPedidos} />
                            </ModalCustom>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'row',
                            }}>
                                <Button sx={{
                                    backgroundColor: itemSelecionado === 0 ? '#F9146B' : '#fff',
                                    color: itemSelecionado === 0 ? '#fff' : '#000',
                                    border: `1px solid ${itemSelecionado === 0 ? '#c11154' : '#000'}`,
                                    '&:hover': {
                                        backgroundColor: itemSelecionado === 0 ? '#c11154' : '#c4c4c4',
                                    },
                                    fontSize: '0.9rem',
                                }} onClick={() => {
                                    setItemSelecionado(0)
                                }}>
                                    Pedidos Abertos
                                </Button>
                                <div style={{
                                    width: size.x > 700 ? '20px' : '10px',
                                }}></div>
                                <Button sx={{
                                    backgroundColor: itemSelecionado === 1 ? '#F9146B' : '#fff',
                                    color: itemSelecionado === 1 ? '#fff' : '#000',
                                    border: `1px solid ${itemSelecionado === 1 ? '#c11154' : '#000'}`,
                                    '&:hover': {
                                        backgroundColor: itemSelecionado === 1 ? '#c11154' : '#c4c4c4',
                                    },
                                    fontSize: '0.9rem',
                                }} onClick={() => {
                                    setItemSelecionado(1)
                                }}>
                                    Pedidos Finalizados
                                </Button>
                                <div style={{
                                    width: size.x > 700 ? '20px' : '10px',
                                }}></div>
                                <Button sx={{
                                    backgroundColor: itemSelecionado === 2 ? '#F9146B' : '#fff',
                                    color: itemSelecionado === 2 ? '#fff' : '#000',
                                    border: `1px solid ${itemSelecionado === 2 ? '#c11154' : '#000'}`,
                                    '&:hover': {
                                        backgroundColor: itemSelecionado === 2 ? '#c11154' : '#c4c4c4',
                                    },
                                    fontSize: '0.9rem',
                                }} onClick={() => {
                                    setItemSelecionado(2)
                                }}>
                                    Pedidos Cancelados
                                </Button>
                            </div>
                            <div style={{
                                height: '40px',
                            }}></div>
                            <Box>
                                {
                                    isLoading ?
                                        <Box>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'column',
                                                padding: '20px',
                                            }}>
                                                <CircularProgress sx={{
                                                    color: '#F9146B',
                                                }} />
                                                <br></br>
                                                <Typography sx={{
                                                    fontSize: '20px',
                                                }}>
                                                    Carregando...
                                                </Typography>
                                            </div>
                                        </Box>
                                        : pedidos === null ?
                                            <Box>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                    padding: '20px',
                                                }}>
                                                    <Typography sx={{
                                                        fontSize: '20px',
                                                    }}>
                                                        Erro ao encontrar pedido
                                                    </Typography>
                                                </div>
                                            </Box>
                                            : pedidos.length === 0 ?
                                                <Box>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        flexDirection: 'column',
                                                        padding: '20px',
                                                    }}>
                                                        <Typography sx={{
                                                            fontSize: '20px',
                                                        }}>
                                                            Nenhum pedido encontrado
                                                        </Typography>
                                                    </div>
                                                </Box>
                                                : pedidos?.map((pedido, index) => {
                                                    return (
                                                        <Card sx={{
                                                            width: size.x > 1000 ? '700px' : '100%',
                                                            backgroundColor: '#fff',
                                                            borderRadius: '5px',
                                                            margin: `${size.x > 1000 ? '10px' : '2px'} 0px`,
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }} elevation={5} key={index}>
                                                            <div style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                backgroundColor: '#FAE600',
                                                                padding: size.x > 1000 ? '10px' : 0,
                                                            }}>
                                                                <Typography style={{
                                                                    margin: '10px',
                                                                    fontSize: '1rem',
                                                                }}>
                                                                    {pedido.createdatlbl}
                                                                </Typography>
                                                                <Typography style={{
                                                                    margin: '10px',
                                                                    fontSize: '1rem',
                                                                }}>
                                                                    Pedido: {pedido.id}
                                                                </Typography>
                                                                <Typography style={{
                                                                    margin: '10px',
                                                                    fontSize: '1rem',
                                                                }}>
                                                                    Valor: {pedido.valorFinal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                                </Typography>
                                                            </div>
                                                            <div style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                paddingBottom: pedido.status === 7 ? '0px' : '20px',
                                                                paddingTop: '20px',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                textAlign: 'center',
                                                            }}>
                                                                {
                                                                getObjJSXforStatusPedido(
                                                                    pedido.historico, 
                                                                    pedido.status === 7, 
                                                                    pedido.qrcode, 
                                                                    pedido.status,
                                                                    pedido.hash_cliente,
                                                                )}
                                                            </div>
                                                            <div style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                padding: 0,
                                                                width: '100%',
                                                                marginTop: '15px',
                                                                marginBottom: '10px',
                                                                textAlign: 'center',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}>
                                                                {rowComponentJSXForValuePedido(pedido)}
                                                            </div>
                                                            {
                                                                pedido.qrcode != null &&
                                                                pedido.status === -1 &&
                                                                <div style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                }}>
                                                                    <ModalCustom
                                                                        open={openModalPix}
                                                                        title='Realizar pagamento com o pix'
                                                                        onClose={handleCloseModalPix}
                                                                        actions={null}
                                                                        isMobile={size.x < 700}
                                                                        slug={''}
                                                                    >
                                                                        <ModalMakePix
                                                                            onClose={handleCloseModalPix}
                                                                            qrcode={pedido.qrcode}
                                                                            onReload={() => getPedidosFunction()}
                                                                        />
                                                                    </ModalCustom>
                                                                    <Button sx={{
                                                                        margin: '10px 10px 15px 10px',
                                                                        color: '#F9146B',
                                                                        border: '1px solid #F9146B',
                                                                    }} onClick={() => {
                                                                        setOpenModalPix(true)
                                                                    }}>
                                                                        Realizar pagamento com o pix
                                                                    </Button>
                                                                </div>
                                                            }
                                                            <div style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                marginTop: '10px',
                                                                paddingLeft: size.x > 700 ? '20px' : 0,
                                                                paddingRight: size.x > 700 ? '20px' : 0,
                                                            }}>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    marginLeft: '10px',
                                                                    marginRight: '10px',
                                                                }}>
                                                                    <CardMedia
                                                                        component="img"
                                                                        image={pedido.new_logo.length < 4 ? 'https://vkadm.vokere.com/static/media/logo-horizontal-negativo.9e2f1a48.png' : pedido.new_logo}
                                                                        alt="Imagem da empresa"
                                                                        sx={{
                                                                            width: '100px',
                                                                            height: '100px',
                                                                            borderRadius: '5%',
                                                                            marginRight: '10px',
                                                                        }}
                                                                    />
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                    }}>
                                                                        <Typography sx={{
                                                                            fontWeight: 'bold',
                                                                            fontSize: '0.9rem',
                                                                        }}>
                                                                            {pedido.empresa}
                                                                        </Typography>
                                                                        <Typography>
                                                                            {pedido.empresa_aberta === 1 ? 'Aberto 🟢' : 'Fechado 🔴'}
                                                                        </Typography>
                                                                        {
                                                                            size.x > 700 ? null : btnIrParaLoja(pedido.empresa_slug)
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {
                                                                    size.x > 700 ? btnIrParaLoja(pedido.empresa_slug) : null
                                                                }
                                                            </div>
                                                            <div style={{
                                                                marginTop: '20px',
                                                                height: '50px',
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                padding: '10px',
                                                            }}>
                                                                {
                                                                    !pedido.historico.aceito && pedido.status !== 7 ?
                                                                        <Button sx={{
                                                                            backgroundColor: '#FAE600',
                                                                            color: '#000',
                                                                            fontWeight: 'bold',
                                                                            fontSize: size.x > 900 ? '0.9rem' : '0.8rem',
                                                                            width: '100%',
                                                                            height: '50px',
                                                                            marginRight: 0.5,
                                                                            '&:hover': {
                                                                                backgroundColor: '#F9146B',
                                                                                color: '#fff',
                                                                            },
                                                                        }} onClick={() => {
                                                                            setIdPedido(pedido.id)
                                                                            handleClickOpenDialog()
                                                                        }}>
                                                                            Cancelar
                                                                        </Button> : null
                                                                }
                                                                <Button sx={{
                                                                    backgroundColor: '#FAE600',
                                                                    color: '#000',
                                                                    fontWeight: 'bold',
                                                                    fontSize: size.x > 900 ? '0.9rem' : '0.8rem',
                                                                    width: '100%',
                                                                    height: '50px',
                                                                    '&:hover': {
                                                                        backgroundColor: '#F9146B',
                                                                        color: '#fff',
                                                                    },
                                                                    marginLeft: !pedido.historico.aceito ? 0.5 : 0,
                                                                }} onClick={() => {
                                                                    setPedidoSelecionado(pedido)
                                                                    setOpenModalPedidos(true)
                                                                    handleOpenModalPedidos()
                                                                }}>
                                                                    Mais detalhes
                                                                </Button>
                                                            </div>
                                                        </Card>
                                                    )
                                                })
                                }
                            </Box>
                        </div>
                        :
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                        }}>
                            <Box sx={{
                                padding: '20px',
                                borderRadius: '5px',
                                marginBottom: '20px',
                                color: '#000',
                                border: '1px solid #000',
                            }}>
                                <Typography>
                                    Nenhum usuário encontrado, por favor tente novamente ou faça login.
                                </Typography>
                            </Box>
                            <Button sx={{
                                backgroundColor: '#fff',
                                color: '#F9146B',
                                border: '1px solid #F9146B',
                                fontWeight: 'bold',
                                fontSize: size.x > 700 ? '16px' : '13px',
                                height: '50px',
                                '&:hover': {
                                    backgroundColor: '#F9146B',
                                    color: '#fff',
                                },
                            }} onClick={() => {
                                const url = '/login'
                                window.location.href = url
                            }}>
                                Fazer login
                            </Button>
                        </Box>
                }
            </Grid>
            <div style={{
                height: '80px',
            }}></div>
        </div>
    )
}

export default PedidosPage
