//MUI
import { Box, Grid } from '@mui/material'
//REACT
import React from 'react'
//CSS
import styles from '../secao_vantagem/style.module.css'
//IMAGENS
const mao = require('../../../../assets/images/mao_estalando.png')
const moto = require('../../../../assets/images/scooter_comodidade1.png')

const SecaoVantagem = () => {
  return (
    <Grid>
      <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid className={styles.linha}></Grid>
      </Grid>
      <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
        <h1 className={styles.t_porque}>Por que ser parceiro do Vokerê?</h1>
      </Grid>
      <br></br>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <p className={styles.d_porque}>
          A procura e o uso dos aplicativos de delivery tiveram um crescimento
          expressivo nos ultimos anos e o Vokerê traz para o seu negócio o meio
          mais eficaz de quebrar barreiras geográficas, através dele, o seu
          negócio não fica limitado ao entorno, podendo atrair públicos que
          normalmente não chegariam até você.
        </p>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'space-around',
          justifyContent: 'space-around',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
        className={styles.grid_container}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          flexDirection: 'column',
          border: '10px solid #FFF148',
        }}>
          <img
            src={mao}
            style={{
              height: '217px',
              marginBottom: -10,
            }}
            alt="Mão estalando dedos"
          />
          <h1 style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '25px',
            lineHeight: '144%',
            textAlign: 'center',
            letterSpacing: '0.045em',
            color: '#000000',
            marginTop: 0,
          }}>Praticidade</h1>
          <p className={styles.d_box}>
            Todos os produtos em um app simples e intuitivo.
          </p>
        </Box>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          flexDirection: 'column',
          border: '10px solid #FFF148',
        }}>
          <img
            src={moto}
            style={{
              height: '217px',
              marginBottom: -10,
            }}
            alt="Moto com simbolo de localização acima"
          />
          <h1 style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '25px',
            lineHeight: '144%',
            textAlign: 'center',
            letterSpacing: '0.045em',
            color: '#000000',
            marginTop: 0,
          }}>Comodidade</h1>
          <p className={styles.d_box}>
            O produto chega ao cliente, sem que ele precise se locomover.
          </p>
        </Box>
      </Box>
    </Grid>
  )
}

export default SecaoVantagem
