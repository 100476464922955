//MUI
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'

//CSS
import styles from '../secao_dados/style.module.css'

//IMAGENS
const celular = require('../../../../assets/images/mulher_com_celular.png')
const playstore = require('../../../../assets/images/playstore.png')
const cidade = require('../../../../assets/images/cidade.png')
const pedido = require('../../../../assets/images/pedido.png')

const SecaoDados = () => {


  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight
  });
  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight
    });
  useEffect(() => (window.onresize = updateSize), []);


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor: '#F1DE01',
        paddingBottom: 0,
        alignItems: size.x > 650 ? 'center' : 'start',
        justifyContent: size.x > 650 ? 'center' : 'start',
      }}
    >
      {
        size.x > 650 && <img
          src={celular}
          alt="Mulher segurando celular"
          width={'500px'}
          style={{
            marginBottom: '-108px',
          }}
        />
      }
      <Box sx={{
        marginTop: size.x > 650 ? '40px' : '80px',
      }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          height: '150px',
          width: '250px',
        }}>
          <img
            src={playstore}
            alt="Icone da playstore"
          />
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
            <h1 className={styles.t_playstore}>+ 50 mil</h1>
            <h1 className={styles.d_playstore}>Downloads</h1>
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          height: '150px',
          width: '250px',
        }}>
          <img
            src={cidade}
            alt="Icone de cidade"
          />
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
            <h1 className={styles.t_cidade}>13</h1>
            <h1 className={styles.d_cidade}>Cidades</h1>
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          height: '150px',
          width: '250px',
        }}>
          <img
            src={pedido}
            alt="Icone de sineta"
          />
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
            <h1 className={styles.t_pedido}>+ 1000</h1>
            <h1 className={styles.d_pedido}>Pedidos por dia</h1>
          </Box>
        </Box>
      </Box>
      {
        size.x < 650 && <img
          src={celular}
          alt="Mulher segurando celular"
          width={'330px'}
          style={{
            marginBottom: '-70px',
          }}
        />
      }
    </Box>
  )
}

export default SecaoDados
