const urlAPI = 'https://api.euvokere.com.br';
//const urlAPI = 'http://127.0.0.1:8000';

const getUrlAPIv1 = `${urlAPI}/api/v1`;

const getUrlAPIv2 = `${urlAPI}/api/v2`;

const getUrlAPIv3 = `${urlAPI}/api/v3`;

const getUrlAPIv4 = `${urlAPI}/api/v4`;

const versionWebSite = '0.0.6'

const urlWebSite = 'https://app.euvokere.com.br';

//const urlWebSite = 'http://192.168.18.159:5001';

export { getUrlAPIv1, getUrlAPIv2, getUrlAPIv3, getUrlAPIv4, versionWebSite, urlWebSite };