//MUI
import { Grid } from '@mui/material'

import styles from '../sobre/style.module.css'
import CityPage from './subpage/city'

const Empresas = () => {
  return (
    <div>
      <Grid className={styles.body}>
        <div style={{
          padding: '0%',
          margin: '0%',
        }}>
            <CityPage/>
        </div>
      </Grid>
    </div>
  )
}

export default Empresas
