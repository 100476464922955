import SecaoDados from './components/secao_dados/secao_dados'
import SecaoDeliveryman from './components/secao_deliveryman/secao_deliveryman'
import SecaoEntregador from './components/secao_entregador/secao_entregador'
import SecaoNegociar from './components/secao_negociar/secao_negociar'
import NossoApp from './components/secao_nosso_app/secao_nosso_app'

const Home = () => {
  return (
    <div>
      <SecaoEntregador />
      <SecaoDados />
      <SecaoDeliveryman />
      <SecaoNegociar />
      <NossoApp />
    </div>
  )
}
export default Home
