//CSS

import SecaoForm from './components/secao_form/secao_form'
import SecaoVantagem from './components/secao_vantagem/secao_vantagem'

const Parceiro = () => {
  return (
    <div>
      <SecaoForm />
      <SecaoVantagem />
    </div>
  )
}

export default Parceiro
