import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { CartProvider } from './pages/empresas/context/cartcontext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <CartProvider>
      <App/>
    </CartProvider>
  </React.StrictMode>
);
