import Person from '@mui/icons-material/AccountCircle'
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining'
import FmdBadIcon from '@mui/icons-material/FmdBad'
import HomeIcon from '@mui/icons-material/Home'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import MenuIcon from '@mui/icons-material/Menu'
import StoreIcon from '@mui/icons-material/Store'
import { Button, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import Divider from '@mui/material/Divider'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import styles from '../header/style.module.css'

const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false)

  return (
    <React.Fragment>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List onClick={() => setOpenDrawer(false)}>
          <Link to="/Home" className={styles.links}>
            <ListItemButton>
              <ListItemIcon>
                <HomeIcon />
                <ListItemText
                  sx={{
                    width: 10,
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 18,
                    lineHeight: 22,

                    color: '#403F3F'
                  }}
                >
                  Home
                </ListItemText>
              </ListItemIcon>
            </ListItemButton>

            <Divider component="li" />
          </Link>
          <Link to="/Entregador" className={styles.links}>
            <ListItemButton>
              <ListItemIcon>
                <DeliveryDiningIcon />
                <ListItemText
                  sx={{
                    textDecoration: 'none',
                    listStyle: 'none',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 18,
                    lineHeight: 22,

                    color: '#403F3F'
                  }}
                >
                  Entregador
                </ListItemText>
              </ListItemIcon>
            </ListItemButton>
            <Divider />
          </Link>
          {
            /*
            <Link to="/Franqueado" className={styles.links}>
            <ListItemButton>
              <ListItemIcon>
                <LocationCityIcon />
                <ListItemText
                  sx={{
                    textDecoration: 'none',
                    listStyle: 'none',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 18,
                    lineHeight: 22,

                    color: '#403F3F'
                  }}
                >
                  Franqueado
                </ListItemText>
              </ListItemIcon>
            </ListItemButton>
            <Divider />
            </Link>
            */
          }
          <Link to="/Parceiro" className={styles.links}>
            <ListItemButton>
              <ListItemIcon>
                <Person />
                <ListItemText
                  sx={{
                    textDecoration: 'none',
                    listStyle: 'none',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 18,
                    lineHeight: 22,
                    padding: 0,
                    color: '#403F3F'
                  }}
                >
                  Parceiro
                </ListItemText>
              </ListItemIcon>
            </ListItemButton>
            <Divider />
          </Link>
          <Link to="/login" className={styles.links}>
            <ListItemButton>
              <ListItemIcon>
                <StoreIcon />
                <ListItemText
                  sx={{
                    textDecoration: 'none',
                    listStyle: 'none',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 18,
                    lineHeight: 22,
                    padding: 0,
                    color: '#403F3F'
                  }}
                >
                  Faça seu pedido
                </ListItemText>
              </ListItemIcon>
            </ListItemButton>
            <Divider />
          </Link>
          <Link to="/Sobre" className={styles.links}>
            <ListItemButton>
              <ListItemIcon>
                <FmdBadIcon />
                <ListItemText
                  sx={{
                    textDecoration: 'none',
                    listStyle: 'none',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 18,
                    lineHeight: 22,

                    color: '#403F3F'
                  }}
                >
                  Vokerê
                </ListItemText>
              </ListItemIcon>
            </ListItemButton>
            <Divider />
          </Link>
          <ListItemButton>
            <ListItemText>
              <Button
                href="https://onelink.to/6qh3rv"
                variant="contained"
                sx={{
                  background: '#F9146B',
                  marginLeft: 'auto',
                  fontFamily: 'inter',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: 16,
                  color: '#FFFFFE',
                  borderRadius: 2,

                  '&:hover': {
                    backgroundColor: '#F9146B',
                    opacity: [0.8, 0.8, 0.8]
                  }
                }}
              >
                Baixe o app
              </Button>
            </ListItemText>
          </ListItemButton>
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon />
      </IconButton>
    </React.Fragment>
  )
}

export default DrawerComp
