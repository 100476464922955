import { Grid } from "@mui/material"
import style from './style.module.css'
import { BottomTrabalheCononosco } from "../BottomTrabalheConosco/BottomTrabalheConosco"


export const BodyTrabalheConosco = () => {

    return (
        <Grid>
            <div className={style.trabalheConosco}>
                <h1>
                    Trabalhe Conosco
                </h1>
                <p>
                    Vokere é uma empresa de tecnologia em rápido crescimento e estamos sempre em busca de novos talentos para se juntar à nossa equipe.
                    Se você é apaixonado por tecnologia, comida e serviços de entrega, nós queremos ouvir de você.
                </p>
                <p>
                    Estamos em busca de pessoas comprometidas e dinâmicas para trabalhar em diversas áreas, como atendimento ao cliente, logística,
                    tecnologia e marketing.
                </p>
                <p>
                    Se você está interessado em se juntar à nossa equipe, envie seu currículo para nosso banco de talentos e novas oportunidades você será contato por um de nossos
                    canais de atendimento.
                </p>
                <p>
                    No Vokere, oferecemos oportunidades de crescimento e desenvolvimento de carreira,
                    além de um ambiente de trabalho descontraído e colaborativo. Venha fazer parte do nosso time!
                </p>
            </div>
            <div className={style.buttonForm}>
                <BottomTrabalheCononosco />
            </div>

        </Grid>
    )
}