import { Box, Button, Card, CircularProgress, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import styles from '../../sobre/style.module.css'
import getCities from '../controllers/getCities';
import Cidade from '../model/cidades.model';

const CidadePage = () => {

    const [cities, setCities] = useState<Cidade[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        getCidades();
    }, []);


    async function getCidades(): Promise<void> {
        setLoading(true);
        const city = await getCities();
        if (city) {
            setError(false);
            setCities(city);
        } else {
            setError(true);
        }
        setLoading(false);
    }

    return (
        <div>
            <Grid className={styles.body}>
                <div style={{
                    padding: '0% 0%',
                    margin: '0% 0%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}>
                    <Typography variant="h5" component="p" sx={{
                        marginBottom: '30px',
                    }}>
                        Selecione uma cidade
                    </Typography>
                    {
                        loading ?
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                            }}>
                                <Typography gutterBottom component="h2" style={{
                                    textAlign: 'center',
                                    marginTop: '10px',
                                    marginBottom: '20px',
                                    fontSize: '1.3rem',
                                }}>
                                    Carregando cidades...
                                </Typography>
                                <CircularProgress sx={{
                                    color: '#F9146B',
                                }} />
                            </Box> :
                            error ? <div>

                                <Box sx={{ width: '100%' }}>
                                    <Typography gutterBottom component="h2" style={{
                                        textAlign: 'center',
                                        width: '100%',
                                    }}>
                                        <h2>Erro ao carregar cidades</h2>
                                        <br></br>
                                        <Button variant="contained" onClick={() => getCidades()}>Tentar novamente</Button>
                                    </Typography>
                                </Box>

                            </div> : <Grid container spacing={1} sx={{
                                textAlign: 'center',
                                alignItems: 'start',
                                width: '100%',
                                paddingLeft: '5%',
                                paddingRight: '5%',
                            }}>
                                {cities.map((city, index) => {
                                    return (
                                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                            <Typography variant="h6" component="p">
                                                <Link to={`./cidade/${city.id}/${city.nome}`}>
                                                    <Grid>
                                                        <Card
                                                            variant="outlined"
                                                            elevation={6}
                                                            sx={{
                                                                textAlign: 'center',
                                                                padding: '10px',
                                                                margin: '15px',
                                                                background: 'linear-gradient(45deg, #ff0061 30%, #F9146B 90%)',
                                                                border: 0,
                                                                borderRadius: 2,
                                                                color: 'white',
                                                                height: 130,
                                                                '&:hover': {
                                                                    background: 'linear-gradient(45deg, #feE000 30%, #ffEE15 90%)',
                                                                    color: 'black',
                                                                    fontWeight: 'w500',
                                                                },
                                                                boxShadow: '0 3px 5px 2px #AEAEAE',
                                                            }}
                                                        >
                                                            <>
                                                                <p>{city.nome}</p>
                                                            </>
                                                            <Typography sx={{ fontSize: 14 }} gutterBottom>
                                                                {city.sigla}
                                                            </Typography>
                                                        </Card>

                                                    </Grid>
                                                </Link>
                                            </Typography>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                    }
                </div>
            </Grid>
            <div style={{
                height: '80px',
            }}></div>
        </div>
    )
}

export default CidadePage
