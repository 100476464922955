import SecaoSobre from './secao_sobre'

const Sobre = () => {
  return (
    <div>
      <SecaoSobre />
    </div>
  )
}

export default Sobre