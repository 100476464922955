import ModalInfoApp from './modal/infoOpenInApp/info';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import BusinessIcon from '@mui/icons-material/Business';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import MoreIcon from '@mui/icons-material/More';
import ShareIcon from '@mui/icons-material/Share';
import StarIcon from '@mui/icons-material/Star';
import { Box, Button, Card, CardActions, CardContent, CardMedia, CircularProgress, FormControlLabel, Grid, IconButton, Switch, TextField, Tooltip, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';
import ReactPixel from 'react-facebook-pixel';
import { useParams } from 'react-router-dom'

import { ModalCustom } from '../../../components/modal';
import Notificacao from '../../../components/notification';
import { urlWebSite } from '../api';
import BACKGROUND_WALLPAPER from '../business-icons.png';
import { useCart } from '../context/cartcontext';
import { getNewEmpresaDetail } from '../controllers/getEmpresaDetail';
import { getImageProfile } from '../controllers/getImageProfile';
import { getProductsGrouped } from '../controllers/getProducts';
import { Address as AddressModel } from '../model/address.model';
import { NewEmpresaModel } from '../model/newEmpresas';
import { Root as ProdutoModel } from '../model/produto.model';
import { ProdutoGrouped } from '../model/produtoGrouped';
import { Root as UserModel } from '../model/user.model';
import FALLBACK_IMAGE from '../onerror.png';
import ModalHorarios from './modal/horarios/modalHorarios';
import ModalProdutos from './modal/produtos/modalProdutos';
import { getInfoShopingCart } from './widgets/shopingCartFloating'

const EmpresaDetail = () => {

    const { cart, setCartAndSave } = useCart();
    const { slugEmpresa } = useParams();
    const [loadingEmpresa, setLoadingEmpresa] = useState(true);
    const [loadingProdutos, setLoadingProdutos] = useState(true);
    const [empresa, setEmpresa] = useState<NewEmpresaModel>();
    const [produtos, setProdutos] = useState<ProdutoModel[]>([]);
    const [produtosGrouped, setProdutosGrouped] = useState<ProdutoGrouped[]>([]);
    const [address, setAddress] = useState<AddressModel>();
    const [error, setError] = useState([false, false]);
    const [open, setOpen] = useState(false);
    const [openModalHorarios, setOpenModalHorarios] = useState(false);
    const [produtoFirst, setProdutoFirst] = useState<ProdutoModel | null>(null);
    const [name, setName] = useState('');
    const userInString = localStorage.getItem('user') || '{}'
    const [openInfoPerson, setOpenInfoPerson] = useState(false);
    const [enableGroupedProducts, setEnableGroupedProducts] = useState(false);
    const [openModalInfoApp, setOpenModalInfoApp] = useState(false);
    const handleCloseModalInfoApp = () => setOpenModalInfoApp(false);

    const onMediaFallback = (event: any) => event.target.src = FALLBACK_IMAGE;

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOpenModalHorarios = () => setOpenModalHorarios(true);
    const handleCloseModalHorarios = () => setOpenModalHorarios(false);

    const [imageUrl, setImageUrl] = useState(FALLBACK_IMAGE)

    async function getImage() {
        const userInString = await localStorage.getItem('user') || '{}'
        if (userInString.length < 5) return
        const user = JSON.parse(userInString) as UserModel
        const image = await getImageProfile(user.user.id)
        if (image) {
            setImageUrl(image)
        }
    }

    const [size, setSize] = useState({
        x: window.innerWidth,
        y: window.innerHeight
    });
    const updateSize = () =>
        setSize({
            x: window.innerWidth,
            y: window.innerHeight
        });
    useEffect(() => (window.onresize = updateSize), []);


    function getProdutosByName(): ProdutoModel[] {
        if (name === '') return produtos;
        return produtos.filter((produto) => {
            return produto.nome.toLowerCase().includes(name.toLowerCase());
        });
    }

    async function getEmpresaAndProdutosAndAddress(): Promise<void> {
        await getImage()
        if (slugEmpresa) {
            // is loading business
            setLoadingEmpresa(true);
            const empresaDetail = await getNewEmpresaDetail(slugEmpresa);
            if (empresaDetail) {
                setError([false, error[1], error[2]]);
                setEmpresa(empresaDetail);
            } else {
                setError([true, error[1], error[2]]);
            }
            setLoadingEmpresa(false);
            //is loading  products 
            setLoadingProdutos(true);
            if (empresaDetail) {
                const productGouped = await getProductsGrouped(empresaDetail.id);
                setProdutos(empresaDetail.produtos);
                if (productGouped) {
                    setError([error[0], false, error[2]]);
                    setProdutosGrouped(productGouped);
                    setError([error[0], error[1], false]);
                    setAddress(empresaDetail.endereco);
                }
            }
            setLoadingProdutos(false);

            console.log('empresaDetail', empresaDetail?.id);

            if (empresaDetail?.id === 2517) {
                import('react-facebook-pixel')
                    .then((x) => x.default)
                    .then((ReactPixel) => {
                        console.log('ReactPixel', ReactPixel);
                        ReactPixel.init('3338563616458100') // facebookPixelId
                        ReactPixel.pageView()
                    })
            } else {
                import('react-facebook-pixel')
                    .then((x) => x.default)
                    .then((ReactPixel) => {
                        console.log('ReactPixel', ReactPixel);
                        ReactPixel.init('1426348784794327') // facebookPixelId
                        ReactPixel.pageView()
                    })
            }

            //isLoadingAddress
        }
    }

    function getStarsFromBusiness(quantStars: number) {
        let stars = [];
        for (let i = 0; i < quantStars; i++) {
            stars.push(<StarIcon style={{ color: '#FFC107' }} />);
        }
        return stars;
    }

    function getIconsFromAddress(key: string, value: string, icon: any) {
        return <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center',
            width: '100%',
            marginBottom: '5px',
        }}>
            <div style={{
                marginRight: '10px',
            }}>
                {icon}
            </div>
            <strong style={{
                marginRight: '5px',
                fontSize: '0.8rem',
            }}
            >{key}</strong>
            <div style={{
                fontSize: '0.8rem',
            }}>
                {value}
            </div>
        </div>
    }

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/.test(userAgent);

        if (isMobile) {
            setOpenModalInfoApp(true);
        }
        getEmpresaAndProdutosAndAddress()
    }, []);

    const showInMapClicked = (lat: number, lng: number) => {
        window.open("https://maps.google.com?q=" + lat + "," + lng);
    };

    const styleBtnSeeMore = {
        backgroundColor: '#FFF',
        color: '#F9146B',
        border: '1px solid #F9146B',
        fontWeight: 'bold',
        fontSize: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: size.x > 600 ? '40px' : '0px',
    }

    function productInCart(id: number): boolean {
        if (cart.length > 0) {
            return cart.some((item: ProdutoModel) => item.id === id);
        }
        return false;
    }

    function getProdutoForMobile(
        key: number,
        produto: ProdutoModel,
        hasCategorias: boolean,
    ): JSX.Element {
        const fotoProd = produto.foto && produto.foto.length > 5 ? 'https://devvokere.s3.us-east-2.amazonaws.com/' + produto.foto : '';
        return <Card sx={{
            width: hasCategorias ? '93%' : '100%',
            padding: '5px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '8px',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#f5f5f5',
                boxShadow: '0px 0px 3px 3px #FAE600',
            }
        }}
            elevation={4}
            onClick={() => {
                setProdutoFirst({
                    ...produto,
                    foto: fotoProd,
                });
                handleOpen();
            }}
            key={key}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                <img src={fotoProd.length < 4 ?
                    'https://vkadm.vokere.com/static/media/logo-horizontal-negativo.9e2f1a48.png' :
                    fotoProd
                } alt="Imagem da empresa"
                    onError={onMediaFallback}
                    style={{
                        width: size.x > 700 ? '160px' : '120px',
                        height: size.x > 700 ? '160px' : '120px',
                        objectFit: produto.foto == null ? 'contain' : 'cover',
                        marginBottom: size.x > 700 ? '10px' : '5px',
                        background: produto.foto === null ? 'linear-gradient(45deg, #2f3541 30%, #2f353e 90%)' : '#000',
                    }} />
                <Box style={{
                    marginLeft: '10px',
                    justifyContent: 'space-between',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Box sx={{
                        textAlign: 'start',
                        justifyContent: 'start',
                        paddingTop: '5px',
                        paddingBottom: '10px',
                    }}>
                        <Typography variant="h5" sx={{
                            fontSize: '0.9rem',
                            fontWeight: 'bold',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            textOverflow: 'ellipsis',
                            maxLines: 1,
                        }}>{produto.nome}</Typography>
                        <Typography variant="h5" sx={{
                            marginRight: '10px',
                            fontSize: '0.8rem',
                            textAlign: 'justify',
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 4,
                            textOverflow: 'ellipsis',
                            maxLines: 4,
                        }}>
                            {produto.descricao === 'null' ? '(Sem descrição)' : produto.descricao}
                        </Typography>
                    </Box>
                    <Typography gutterBottom component="h3" style={{
                        textAlign: 'start',
                        color: productInCart(produto.id) ? '#F9146B' : '#000',
                        fontSize: '0.8rem',
                        fontWeight: 'bold',
                    }}>
                        {produto.especificacoes === null || produto.especificacoes.length === 0 ? produto.preco.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                            : <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                margin: '0px',
                                padding: '0px',
                            }}>
                                <h5 style={{
                                    margin: '0px',
                                    padding: '0px',
                                    fontSize: '0.8rem',
                                    color: productInCart(produto.id) ? '#F9146B' : '#000',
                                }}>
                                    A partir de:
                                </h5>
                                <h3 style={{
                                    margin: '0px',
                                    padding: '0px',
                                    fontSize: '0.8rem',
                                    color: productInCart(produto.id) ? '#F9146B' : '#000',
                                }}>{produto.preco.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h3>
                            </div>}
                    </Typography>
                </Box>
            </div>
        </Card>;
    }

    function getCardForDetailAddres(empresa: NewEmpresaModel): JSX.Element {
        return <Card elevation={8} sx={{
            marginTop: size.x > 600 ? '10px' : 0,
            minWidth: 400,
            width: size.x > 600 ? '49%' : '100%',
            '@media (max-width:1200px)': {
                width: '100%',
                minWidth: '100%',
                marginTop: size.x > 600 ? '30px' : 0,
            },
        }}>
            <CardContent
                sx={{
                    color: '#000',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'space-between',
                    justifyContent: 'space-between',
                    borderRadius: '5px',
                    borderLeft: `${size.x > 700 ? '8px' : '4px'} solid #FAE600`,
                    height: '100%',
                    backgroundImage: `url(${BACKGROUND_WALLPAPER})`,
                }}>
                {
                    <Box sx={{
                        background: '#FFF',
                        border: '1px solid #cfcfcf',
                        padding: '10px',
                        borderRadius: '5px',
                        height: !address ? '70%' : 'auto',
                    }}>
                        {

                            address ?
                                <Typography variant="body2" color="text.secondary" sx={{
                                    fontSize: '15px',
                                    textAlign: 'justify',
                                    color: '#000',
                                }}>
                                    <Typography sx={{
                                        marginBottom: '15px',
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                    }}>
                                        Endereço:
                                    </Typography>
                                    {getIconsFromAddress('Rua:  ', address.logradouro, <BusinessIcon />)}
                                    {getIconsFromAddress('Numero:  ', address.numero, <LooksOneIcon />)}
                                    {getIconsFromAddress('Bairro:  ', address.bairro, <ApartmentIcon />)}
                                    {getIconsFromAddress('Cidade:  ', address.cidade, <LocationCityIcon />)}
                                    {getIconsFromAddress('Complemento:  ', address.complemento, <MoreIcon />)}
                                </Typography>
                                : <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    width: '100%',
                                    height: '100%',
                                }}>
                                    <CircularProgress sx={{
                                        color: '#F9146B',
                                    }} />
                                </Box>
                        }
                    </Box>
                }
                {
                    empresa.retirar_local ? <Button onClick={() => {
                        showInMapClicked(address?.latitude, address?.longitude)
                    }}
                        style={{
                            ...styleBtnSeeMore,
                            marginTop: size.x > 600 ? '0px' : '20px',
                            marginBottom: '10px',
                        }}
                    >
                        <Typography sx={{
                            fontSize: '0.8rem',
                        }}>
                            Abrir localização no Google Maps
                        </Typography>
                    </Button> : null
                }
                <Button onClick={() => {
                    setOpenModalHorarios(true)
                    handleOpenModalHorarios();
                }}
                    style={{
                        ...styleBtnSeeMore,
                        marginTop: size.x > 600 ? '0px' : '10px',
                    }}
                >
                    <Typography sx={{
                        fontSize: '0.8rem',
                    }}>
                        Ver horários de funcionamento
                    </Typography>
                </Button>
                <ModalCustom
                    open={openModalHorarios}
                    title='Horários de funcionamento'
                    onClose={handleCloseModalHorarios}
                    actions={null}
                    isMobile={size.x < 700}
                    slug={''}
                >
                    <ModalHorarios
                        onClose={handleCloseModalHorarios}
                        empresa={empresa}
                    />
                </ModalCustom>

            </CardContent>
        </Card>;
    }

    function getProdutosForDesktop(
        key: number,
        produto: ProdutoModel,
        hasCategorias: boolean,
    ): JSX.Element {
        const imagemUrl = produto.foto && produto.foto.length > 5 ? 'https://devvokere.s3.us-east-2.amazonaws.com/' + produto.foto : 'https://vkadm.vokere.com/static/media/logo-horizontal-negativo.9e2f1a48.png';
        return <Grid item xs={12} sm={6} md={4} lg={3} key={key}>
            <Card
                onClick={() => {
                    setProdutoFirst({
                        ...produto,
                        foto: imagemUrl,
                    });
                    handleOpen();
                }}
                sx={{
                    marginTop: size.x > 790 ? '10px' : '5px',
                    marginBottom: size.x > 790 ? '10px' : '5px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': {
                        boxShadow: '0px 0px 3px 3px #FAE600',
                    },
                    width: hasCategorias || name.length > 0 ? '90%' : '100%',
                    height: 300,
                }}
                elevation={3}
            >
                <CardMedia
                    component="img"
                    height="140"
                    image={imagemUrl}
                    alt="green iguana"
                    onError={onMediaFallback}
                    sx={{
                        objectFit: (produto.foto === null || produto.foto === '') ? 'contain' : 'cover',
                        width: '100%',
                        background: produto.foto === null ? 'linear-gradient(45deg, #2f3541 30%, #2f353e 90%)' : '#000',
                    }}
                />
                <Tooltip title={produto.nome}>
                    <CardContent sx={{
                        marginBottom: 0,
                        paddingBottom: produto.especificacoes === null || produto.especificacoes.length === 0 ? 2.3 : 0.5,
                    }}>
                        <Typography gutterBottom variant="h6" component="div" style={{
                            fontSize: '0.9rem',
                            textAlign: 'justify',
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            fontWeight: 'bold',
                        }}>
                            {produto.nome}
                        </Typography>
                    </CardContent>
                </Tooltip>
                <CardActions>
                    <Typography gutterBottom component="h3" style={{
                        textAlign: 'center',
                        width: '100%',
                        margin: '0px',
                        color: productInCart(produto.id) ? '#F9146B' : '#000',
                        fontSize: '0.8rem',
                        fontWeight: 'bold',
                    }}>
                        {produto.especificacoes === null || produto.especificacoes.length === 0 ?
                            <Typography sx={{
                                marginTop: 1,
                                fontSize: '0.84rem',
                                fontWeight: 'bold',
                            }}>
                                {produto.preco.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </Typography>
                            : <Box style={{
                                display: 'flex',
                                flexDirection: 'column',
                                margin: '0px',
                                padding: '0px',
                            }}>
                                <Box style={{
                                    margin: '0px',
                                    padding: '0px',
                                    fontSize: '0.8rem',
                                    color: productInCart(produto.id) ? '#F9146B' : '#000',
                                }}>
                                    A partir de:
                                </Box>
                                <Box style={{
                                    margin: '0px',
                                    padding: '0px',
                                    color: productInCart(produto.id) ? '#F9146B' : '#000',
                                }}>{produto.preco.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Box>
                            </Box>}
                    </Typography>

                </CardActions>
                <CardActions style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxAlign: 'center',
                }}>
                    <Typography gutterBottom variant="h5" sx={{
                        fontSize: '0.8rem',
                        fontWeight: 'bold',
                        color: productInCart(produto.id) ? '#F9146B' : '#000',
                    }}>
                        {produto.especificacoes === null || produto.especificacoes.length === 0 ? 'Selecionar produto' : 'Ver opções'}
                    </Typography>
                </CardActions>
            </Card>
        </Grid>
    }

    const styleBtnHeader = {
        display: 'flex',
        backgroundColor: '#FAE600',
        borderColor: '#F9146B',
        '&:hover': {
            backgroundColor: '#FAE600',
            borderColor: '#F9146B',
        },
    };

    return (
        <div>
            <Grid sx={{
                marginTop: size.x > 700 ? '80px' : '60px',
            }}>
                <div style={{
                    padding: `0% ${size.x > 790 ? size.x < 1000 ? '5px' : '10%' : '0%'}`,
                }}>

                    <>
                        {
                            loadingEmpresa ?
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                }}>
                                    <Typography gutterBottom component="h2" style={{
                                        textAlign: 'center',
                                        marginTop: '10px',
                                        marginBottom: '20px',
                                        fontSize: '1.3rem',
                                    }}>
                                        Carregando empresa...
                                    </Typography>
                                    <CircularProgress sx={{
                                        color: '#F9146B',
                                    }} />
                                </Box> :
                                error[0] ?
                                    <Box sx={{ width: '100%' }}>
                                        <Typography gutterBottom component="h2" style={{
                                            textAlign: 'center',
                                            width: '100%',
                                            margin: '5%',
                                            marginTop: '10px',
                                            marginBottom: '6px',
                                            fontSize: '1.3rem',
                                        }}>
                                            Erro ao carregar empresa
                                        </Typography>
                                    </Box> :
                                    empresa ?
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                            justifyContent: 'space-between',
                                        }}>
                                            {
                                                getInfoShopingCart(size.x, cart, openInfoPerson, setOpenInfoPerson, imageUrl, setCartAndSave)
                                            }
                                            <Card sx={{
                                                marginTop: '10px',
                                                minWidth: 400,
                                                width: size.x > 600 ? '49%' : '100%',
                                                '@media (max-width:1200px)': {
                                                    width: '100%',
                                                    minWidth: '100%',
                                                    marginTop: '30px',
                                                },
                                            }}
                                                elevation={6}
                                            >
                                                <div>
                                                    <Box sx={{
                                                        position: 'relative',
                                                    }}>
                                                        <CardMedia
                                                            component="img"
                                                            height="200"
                                                            image={empresa?.new_logo === null ? 'https://vkadm.vokere.com/static/media/logo-horizontal-negativo.9e2f1a48.png' : empresa.new_logo}
                                                            alt="green iguana"
                                                            onError={onMediaFallback}
                                                            sx={{
                                                                objectFit: 'contain',
                                                                backgroundImage: `url(${BACKGROUND_WALLPAPER})`,
                                                                backgroundColor: '#FAE600',
                                                                position: 'static',
                                                            }}
                                                        />
                                                        <Box sx={{
                                                            display: 'flex',
                                                            position: 'absolute',
                                                            top: '10px',
                                                            left: '10px',
                                                        }}>
                                                            <Button
                                                                variant="outlined"
                                                                sx={styleBtnHeader}
                                                                onClick={() => {
                                                                    window.history.back();
                                                                }}
                                                            >
                                                                <ArrowBackIosIcon sx={{
                                                                    color: '#F9146B',
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    justifyContent: 'center',
                                                                    marginLeft: '10px',
                                                                }} />
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                    <CardContent>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between',
                                                        }}>
                                                            <Typography gutterBottom variant="h5" component="div" sx={{
                                                                width: '85%',
                                                                fontSize: '1rem',
                                                            }}>
                                                                {empresa.nome}
                                                            </Typography>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                            }}>
                                                                <Typography color="div" sx={{
                                                                    width: '155px',
                                                                    textAlign: 'end',
                                                                    fontWeight: 'bold',
                                                                    fontSize: '0.8rem',
                                                                }}>
                                                                    {empresa.aberta ? 'Aberta 🟢' : 'Fechada 🔴'}

                                                                </Typography>
                                                                <Typography sx={{
                                                                    fontWeight: 'bold',
                                                                    width: '155px',
                                                                    textAlign: 'end',
                                                                    fontSize: '0.8rem',
                                                                }}>
                                                                    {empresa.retirar_local === 1 ? 'Retirada no local ✅' : 'Retirada no local ❌'}
                                                                </Typography>
                                                            </Box>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: 'start',
                                                                color: '#000',
                                                                width: '100%',
                                                            }}>
                                                            <Typography variant="body2" color="text.secondary">
                                                                {empresa.descricao === 'null' ? `Sem descrição` : empresa.descricao}
                                                            </Typography>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'end',
                                                            }}>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    justifyContent: 'space-between',
                                                                }}>
                                                                    {getStarsFromBusiness(parseInt(empresa.nota))}
                                                                </div>
                                                            </Box>
                                                        </div>
                                                    </CardContent>
                                                </div>
                                                <CardActions sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                }}>
                                                    <CopyToClipboard text={`${urlWebSite}/empresa/${empresa.slug}`} onCopy={() => {
                                                        Notificacao('Sucesso!', `Link da empresa copiado para área de transferência!`, 'success');
                                                    }}>
                                                        <Button sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            flexWrap: 'wrap',
                                                            alignItems: 'center',
                                                        }}>
                                                            Compartilhar
                                                            <IconButton aria-label="share">
                                                                <ShareIcon />
                                                            </IconButton>
                                                        </Button>
                                                    </CopyToClipboard>
                                                    <Box style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        flexWrap: 'wrap',
                                                    }}>
                                                        <Typography sx={{
                                                            fontSize: '0.8rem',
                                                        }}>
                                                            Tempo médio
                                                        </Typography>
                                                        <Typography sx={{
                                                            marginRight: '10px',
                                                            fontSize: '0.8rem',
                                                            fontWeight: 'bold',
                                                        }}>:  {empresa.tempo_medio} min</Typography>
                                                    </Box>
                                                </CardActions>
                                            </Card>

                                            {
                                                size.x > 600 ? getCardForDetailAddres(empresa) : <Card style={{
                                                    width: '100%',
                                                    marginTop: '20px',
                                                }} elevation={3}>
                                                    <Accordion sx={{
                                                        width: '100%',
                                                    }}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Button>
                                                                <Typography sx={{
                                                                    fontSize: '1.1rem',
                                                                }}>
                                                                    Endereço e horários:
                                                                </Typography>
                                                            </Button>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            {getCardForDetailAddres(empresa)}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Card>
                                            }

                                        </div> :
                                        <Box sx={{ width: '80%' }}>
                                            <Typography gutterBottom component="h2" style={{
                                                textAlign: 'center',
                                                width: '100%',
                                                margin: '5%',
                                            }}>
                                                Empresa não encontrada
                                            </Typography>
                                        </Box>

                        }
                        <div style={{
                            marginTop: size.x > 600 ? '30px' : '23px',
                            marginBottom: size.x > 600 ? '30px' : '23px',
                        }}></div>
                        {
                            loadingProdutos ?
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                }}>
                                    <Typography gutterBottom component="h2" style={{
                                        textAlign: 'center',
                                        marginTop: '30px',
                                        marginBottom: '20px',
                                        fontSize: '1.3rem',
                                    }}>
                                        Carregando produtos...
                                    </Typography>
                                    <CircularProgress sx={{
                                        color: '#F9146B',
                                    }} />
                                </Box> :
                                <Card elevation={3}>
                                    <Card style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                height: 70,
                                                paddingBottom: '5px',
                                                paddingTop: '5px',
                                                marginBottom: '15px',
                                                background: 'linear-gradient(90deg, rgba(255,228,19,1) 19%, rgba(255,237,25,1) 56%, rgba(255,237,25,1) 100%)',
                                                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
                                            }}
                                        >
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: '100%',
                                            }}>
                                                <TextField
                                                    sx={{
                                                        width: size.x > 790 ? '48%' : size.x * 0.9,
                                                        backgroundColor: '#fff',
                                                        fontSize: '0.9rem',
                                                        marginLeft: size.x < 600 ? 1 : 3,
                                                    }}
                                                    id="search-bar"
                                                    className="text"
                                                    onChange={(text) => {
                                                        const nomeProd = text.target.value;
                                                        setName(nomeProd);
                                                    }}
                                                    label={size.x < 600 ? "Pesquisar produtos" : "Digite aqui o produto que deseja buscar"}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                                <FormControlLabel
                                                    control={<Switch color='default' />}
                                                    label={
                                                        <Typography sx={{
                                                            fontSize: '0.9rem',
                                                        }}>
                                                            Ocultar categorias
                                                        </Typography>
                                                    }
                                                    labelPlacement="start"
                                                    sx={{
                                                        marginRight: size.x < 600 ? 1 : 3,
                                                    }}
                                                    checked={enableGroupedProducts}
                                                    onChange={() => {
                                                        setEnableGroupedProducts(!enableGroupedProducts);
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            width: '100%',
                                            backgroundImage: `url(${BACKGROUND_WALLPAPER})`,
                                        }}
                                        >
                                            <ModalCustom
                                                open={openModalInfoApp}
                                                title='Atenção'
                                                onClose={handleCloseModalInfoApp}
                                                actions={null}
                                                isMobile={true}
                                                slug={''}
                                            >
                                                <ModalInfoApp onClose={handleCloseModalInfoApp} />
                                            </ModalCustom>
                                            <ModalCustom
                                                open={open}
                                                title={produtoFirst === null ? '' : produtoFirst.nome}
                                                onClose={handleClose}
                                                actions={null}
                                                isMobile={size.x < 700}
                                                slug={!produtoFirst ? null : produtoFirst.slug}
                                            >
                                                <ModalProdutos
                                                    idProduto={null}
                                                    empresaIsOpen={empresa!.aberta === 1}
                                                    onlyView={userInString.length < 3 ? true : false}
                                                    produto={produtoFirst}
                                                    stateFunction={
                                                        () => {
                                                            setOpen(false)
                                                        }
                                                    }
                                                    isRetirada={empresa!.retirar_local === 1}
                                                    handleClose={handleClose}
                                                    isOnCarrinho={false}
                                                    cidade_nome={address ? address.cidade : ''}
                                                    isPromotion={false}
                                                    slugEmpresa={empresa!.slug}
                                                />
                                            </ModalCustom>
                                            {
                                                getProdutosByName().length === 0 ?
                                                    <Typography gutterBottom variant="h6" component="div" style={{
                                                        fontSize: '2rem',
                                                        textAlign: 'justify',
                                                        maxLines: 2,
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        marginTop: '10px',
                                                        marginBottom: '10px',
                                                        display: 'flex',
                                                        backgroundColor: '#fff',
                                                        padding: '20px',
                                                    }}>
                                                        Nenhum produto encontrado
                                                    </Typography>
                                                    : <Grid container spacing={!enableGroupedProducts ? 0 : 1} sx={{
                                                        textAlign: 'center',
                                                        alignItems: 'start',
                                                        width: '100%',
                                                        padding: size.x < 600 ? 0 : 2,
                                                    }}>
                                                        {
                                                            !enableGroupedProducts && name.length === 0 ?


                                                                produtosGrouped.map((produto, index) => {
                                                                    return <Box sx={{
                                                                        width: '100%',
                                                                        marginLeft: 0,
                                                                        padding: 0,
                                                                        marginTop: index === 0 ? '0px' : '10px',
                                                                        marginRight: size.x < 600 ? 0 : 1.5,
                                                                    }} key={index}>
                                                                        <Accordion sx={{
                                                                            width: '100%',
                                                                            alingItems: 'center',
                                                                            textAlign: 'center',
                                                                            justifyContent: 'center',
                                                                            padding: 0,
                                                                            margin: 0,
                                                                            borderLeft: '5px solid #FAE600',
                                                                            borderRight: '5px solid #FAE600',
                                                                        }}>
                                                                            <AccordionSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                aria-controls="panel1a-content"
                                                                                id="panel1a-header"
                                                                            >
                                                                                <Typography sx={{
                                                                                    fontSize: '1.1rem',
                                                                                }}>{produto.nome}</Typography>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails sx={{
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                flexWrap: 'wrap',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                textAlign: 'center',
                                                                                width: '100%',
                                                                                padding: size.x < 600 ? 0 : 2,
                                                                                margin: 0,
                                                                            }}>
                                                                                {
                                                                                    produto.produto.map((produto, index) => {
                                                                                        if (produto.ativo === 0) {
                                                                                            return null;
                                                                                        }

                                                                                        return size.x < 600 ? getProdutoForMobile(index, produto, true) :
                                                                                            getProdutosForDesktop(index, produto, true)
                                                                                    })
                                                                                }
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    </Box>
                                                                })

                                                                :

                                                                getProdutosByName().map((produto, index) => {
                                                                    if (produto.ativo === 0) {
                                                                        return null;
                                                                    }

                                                                    if (produto.especificacoes !== null) {
                                                                        return size.x < 600 ? getProdutoForMobile(index, produto, false) :
                                                                            getProdutosForDesktop(index, produto, false);
                                                                        ;
                                                                    }
                                                                })
                                                        }
                                                    </Grid>
                                            }
                                        </Box>
                                    </Card>
                                </Card>
                        }
                    </>
                </div>
            </Grid>
        </div>
    )
}

export default EmpresaDetail
