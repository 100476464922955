//MUI
import { Box, Grid, Typography } from '@mui/material'
//REACT
import React, { useEffect, useState } from 'react'

//CSS
import styles from '../sobre/style.module.css'

//IMAGENS
const socio1 = require('../../assets/images/socio1.png')
const socio2 = require('../../assets/images/socio2.png')
const socio3 = require('../../assets/images/socio3.png')
const socio4 = require('../../assets/images/socio4.png')
const SecaoSobre = () => {

  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight
  });
  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight
    });
  useEffect(() => (window.onresize = updateSize), []);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <br />
      <br />
      <br />
      <br />
      <Grid sx={{
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
      }}>
        <Typography sx={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '30px',
          textAlign: 'justify',
          color: '#323131',
          maxWidth: 600,
          marginTop: 2,
          width: '90%'
        }}>
          Somos um aplicativo de delivery com o propósito de facilitar a vida de todos.
        </Typography>
      </Grid>
      <Grid container spacing={1} sx={{
        textAlign: 'center',
        alignItems: 'start',
        width: '90%',
        paddingLeft: 2,
        paddingRight: 2,
        marginTop: 3,
        textIndent: '40px'
      }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography paragraph={true} align="justify">
            A trajetória do Vokerê é um conto de visão, paixão e empreendedorismo que começa na vibrante cidade de Balsas, no Maranhão, em 2019. Fruto da colaboração entre Rodrigo Moreira e João Henrique Brilhante, a empresa nasceu do desejo de inovar o setor de delivery na região, oferecendo uma solução que atendesse às necessidades locais com eficiência e qualidade.
            Rodrigo, retornando à sua cidade natal em maio de 2019 após uma transferência profissional bem-sucedida, estava cheio de energia e pronto para investir na sua ideia. Foi durante esse período de reencontro com suas raízes que ele compartilhou a ideia revolucionária de um aplicativo de delivery com Henrique, um amigo de longa data e profissional de destaque em sua área. Henrique, reconhecendo o potencial da proposta, não hesitou em juntar forças com Rodrigo. Juntos, estabeleceram uma parceria baseada na inovação, excelência e na missão compartilhada de transformar o delivery em Balsas.
            Com carreiras consolidadas em grandes instituições financeiras, Rodrigo e Henrique combinaram suas habilidades e experiências para superar os desafios iniciais de equilibrar o desenvolvimento do novo negócio com suas obrigações profissionais. Essa sinergia permitiu-lhes definir uma visão estratégica clara para o Vokerê, focada em qualidade, confiabilidade e satisfação do cliente.
          </Typography>
          <br></br>
          <Typography paragraph={true} align="justify">
            O desenvolvimento do aplicativo começou em julho de 2019 e, apenas alguns meses depois, em outubro, o Vokerê iniciou suas operações de teste. A escolha de iniciar com um grupo selecionado de conhecidos permitiu à equipe refinar o serviço com base em feedback real, garantindo uma experiência de usuário excepcional. À medida que a notícia do novo serviço se espalhava, a demanda crescia exponencialmente, levando a um rápido aumento no volume de pedidos.
            Em menos de um ano, o Vokerê alcançou um marco notável: 10.000 pedidos em um único mês, superando todas as expectativas de faturamento. Este sucesso não era apenas um testemunho da dedicação incansável da equipe, mas também da relevância e necessidade do serviço no contexto atual.
            O Vokerê tornou-se um exemplo emblemático de empreendedorismo inovador, demonstrando como visão estratégica, comprometimento com a excelência e resposta às necessidades da comunidade podem criar um negócio próspero. A história do Vokerê é uma fonte de inspiração para empreendedores aspirantes e uma prova viva de que, com a abordagem certa, é possível transformar ideias em realidade e alcançar sucesso extraordinário.
            Agora, como uma franquia em expansão, o Vokerê oferece a outros empreendedores a chance de fazer parte desta história de sucesso, replicando seu modelo de negócio comprovado e contribuindo para a inovação no setor de delivery em outras comunidades. A empresa continua a crescer e a inovar, mantendo-se fiel à sua missão de oferecer soluções de delivery que excedam as expectativas dos clientes, redefinindo o padrão de serviço no setor.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'space-evenly',
          flexWrap: 'wrap'
        }}>
          <img
            src={socio1}
            alt="imagem dos socios do Vokerê"
            style={{
              maxWidth: size.x > 1000 ? 250 : size.x > 800 ? 200 : 150,
              background: 'linear-gradient(180deg, rgba(255,236,13,1) 43%, rgba(244,225,13,1) 56%, rgba(238,222,13,1) 71%)',
              borderRadius: 20,
              marginTop: 5
            }}
          />
          <img
            src={socio2}
            alt="imagem dos socios do Vokerê"
            style={{
              maxWidth: size.x > 1000 ? 250 : size.x > 800 ? 200 : 150,
              background: 'linear-gradient(180deg, rgba(255,236,13,1) 43%, rgba(244,225,13,1) 56%, rgba(238,222,13,1) 71%)',
              borderRadius: 20,
              marginTop: 5
            }}
          />
        </Grid>
      </Grid>

    </Box>
  )
}

export default SecaoSobre
