import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
//MUI
import { Link } from 'react-router-dom'

//CSS
import styles from '../secao_deliveryman/style.module.css'

//IMAGENS
const deliveryman = require('../../../../assets/images/deliveryman.png')
const scooter = require('../../../../assets/images/scooter.png')

const SecaoDeliveryman = () => {

  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight
  });
  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight
    });
  useEffect(() => (window.onresize = updateSize), []);

  return (
    <Box className={styles.body}>
      <Box sx={{
        marginLeft: '1%',
        marginRight: '1%',
        maxWidth: size.x > 900 ? '60%' : '100%',
        textAlign: 'start',
        alignItems: 'start',
      }}>
        <div className={styles.timeVkr}>
          <img src={scooter} className={styles.scooter} alt="Icone de moto" />
          <p className={styles.t_scooter}>Time Vokerê</p>
        </div>

        <div className={styles.div_texto_entregador}>
          <p className={styles.t_deliveryman} style={{
            marginRight: size.x > 900 ? '20px' : 0,
          }}>
            Venha fazer parte do time, seja nosso entregador
          </p>
          <p className={styles.d_deliveryman}>
            Contamos com uma equipe altamente capacitada para exercer com
            excelência cada função, venha fazer parte desta equipe.
          </p>
        </div>
        <Link to="/Entregador">
          <div className={styles.botao}>
            <p className={styles.t_botao}>Saiba mais</p>
          </div>
        </Link>
      </Box>
      {
        size.x > 900 ? <div className={styles.divAmarelo}>
          <img
            src={deliveryman}
            className={styles.deliveryman}
            alt="Homem segurando café"
          />
        </div> : <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          width: '95%',
        }}>
          <div className={styles.divAmarelo}>
            <img
              src={deliveryman}
              className={styles.deliveryman}
              alt="Homem segurando café"
            />
          </div>
        </Box>
      }
    </Box>
  )
}

export default SecaoDeliveryman
