import styles from '../secao_decisao/style.module.css'
import { Grid } from '@mui/material'
import React from 'react'

const moto = require('../../../../assets/images/moto.png')
const relogio = require('../../../../assets/images/relogio.png')
const mao_dinheiro = require('../../../../assets/images/mao_dinheiro.png')
type Props = {}

const SecaoDecisao = (props: Props) => {
  return (
    <Grid
      sx={{
        background: '#171717',
        marginLeft: -8,
        marginRight: -1,
        marginTop: 10
      }}
      className={styles.body}
    >
      <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
        <h1 className={styles.titulo}>
          Você decide quando e quanto tempo irá trabalhar.
        </h1>
      </Grid>
      <Grid
        sx={{ alignItems: 'center', justifyContent: 'center' }}
        className={styles.grid_container}
      >
        <Grid sx={{ marginLeft: 10 }}>
          <img src={moto} alt="imagem de moto" className={styles.imagem_moto} />
          <div className={styles.divisoria1}></div>
          <p className={styles.t_moto}>Ganhe dinheiro com seu veículo.</p>
        </Grid>
        <Grid sx={{ marginLeft: 10 }}>
          <img
            src={relogio}
            alt="imagem de relogio"
            className={styles.imagem_relogio}
          />
          <div className={styles.divisoria2}></div>
          <p className={styles.t_relogio}>Trabalhe no seu ritmo.</p>
        </Grid>
        <Grid sx={{ marginLeft: 10 }}>
          <img
            src={mao_dinheiro}
            alt="imagem de mao segurando dinheiro"
            className={styles.imagem_mao}
          />
          <div className={styles.divisoria3}></div>
          <p className={styles.t_mao}> Receba pagamentos quinzenais.</p>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SecaoDecisao
