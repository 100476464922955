import React from 'react'

//CSS
import SecaoDecisao from './components/secao_decisao/secao_decisao'
import SecaoSelect from './components/secao_select/secao_select'

const Entregador = () => {
  return (
    <div>
      <SecaoSelect />
      <SecaoDecisao />
    </div>
  )
}

export default Entregador
