import Box from "@mui/material/Box";
import styles from './styles.module.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Formik } from 'formik';
import Divider from '@mui/material/Divider';
import * as Yup from 'yup';
import { Accordion, AccordionDetails, AccordionSummary, Chip, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Switch, TextField, Typography } from "@mui/material";
import { useState } from "react";
import SchoolIcon from '@mui/icons-material/School';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import moment from "moment";
import WorkIcon from '@mui/icons-material/Work';
import { cadastroBancoTalentos } from "../../../empresas/controllers/CadastroBancoTalentos";
import Notificacao from "../../../../components/notification";

interface Formacao {
    tipo: string,
    status: string,
    data_inicio: string,
    data_termino: string,
    curso: string,
    instituicao: string
}
interface ExperienciaProfissional {
    data_inicio: string,
    data_termino: string,
    empresa: string,
    emprego_atual: boolean,
    cargo: string
}

interface UserDadosPessoais {
    name: string,
    estada_civil: string,
    genero: string,
    data_nascimento: string,
    nacionalidade: string,
}
interface Contato {
    fone: string,
    email: string
}

interface Endereco {
    cep: string,
    cidade: string,
    estado: string,
    endereco: string,
}

export const PageFormularioTrabalheConosco = () => {
    const [formacoes, setFormacoes] = useState<Formacao[]>([]);
    const [experienciasProfissionais, setExperienciasProficionais] = useState<ExperienciaProfissional[]>([]);
    const [dadosPessoais, setDadosPessoais] = useState<UserDadosPessoais>({ data_nascimento: '', estada_civil: '', genero: '', nacionalidade: '', name: '' });
    const [listCompentenciasHabilidades, setListCompentenciasHabilidades] = useState<string[]>([])
    const [endereco, setEndereco] = useState<Endereco>({ cep: '', cidade: '', endereco: '', estado: '' });
    const [file, setFile] = useState<File | null>(null);
    const [contato, setContato] = useState<Contato>({ email: '', fone: '' });

    function createFormacao(formacao: Formacao) {
        setFormacoes([...formacoes, {
            curso: formacao.curso,
            data_termino: formacao.data_termino,
            data_inicio: formacao.data_inicio,
            instituicao: formacao.instituicao,
            status: formacao.status,
            tipo: formacao.tipo
        }]);
    }

    function handleSaveDadosPessoais(candidato: UserDadosPessoais) {
        setDadosPessoais({
            data_nascimento: candidato.data_nascimento,
            estada_civil: candidato.estada_civil,
            genero: candidato.genero,
            nacionalidade: candidato.nacionalidade,
            name: candidato.name
        });
    }

    function createExperiencia(experiencia: ExperienciaProfissional) {
        setExperienciasProficionais([...experienciasProfissionais, {
            data_termino: experiencia.data_termino,
            data_inicio: experiencia.data_inicio,
            emprego_atual: experiencia.emprego_atual,
            empresa: experiencia.empresa,
            cargo: experiencia.cargo
        }])
    }

    function handleContato(value: Contato) {
        setContato({ email: value.email, fone: value.fone })
    }

    function handleEndereco(endereco: Endereco) {
        setEndereco({ cep: endereco.cep, cidade: endereco.cidade, endereco: endereco.endereco, estado: endereco.estado })
    }

    function handleChangetCompetenciasHabilidades(valor: string) {
        setListCompentenciasHabilidades([...listCompentenciasHabilidades, valor]);
    }

    function removeCompetenciaHabilidade(habilidade: string) {
        const find = listCompentenciasHabilidades.filter(h => h !== habilidade);
        setListCompentenciasHabilidades(find);
    }

    function removeFormacao(formacao: Formacao) {
        const find = formacoes.filter(f => f !== formacao);
        setFormacoes(find);
    }

    function removeExperiencia(experiencia: ExperienciaProfissional) {
        const find = experienciasProfissionais.filter(e => e !== experiencia);
        setExperienciasProficionais(find);
    }

    return (<div className={styles.body}>
        <Box>
            <Formik
                initialValues={{
                    nome: '',
                    estadoCivil: '',
                    nacionalidade: '',
                    cidade: '',
                    estado: '',
                    cep: '',
                    endereco: '',
                    genero: '',
                    status: '',
                    email: '',
                    curso: '',
                    dataNacimento: '',
                    empresa: '',
                    instituicao: '',
                    dataInicioFormacao: '',
                    dataFimFormacao: '',
                    competenciasEmprego: '',
                    cargo: '',
                    periodoTrabalhadoInicio: '',
                    periodoTrabalhadoFim: '',
                    isEmpregoAtual: false,
                    tipo: '',
                    fone: ''
                }}
                validationSchema={
                    Yup.object({
                        nome: Yup.string().required('Campo nome é obrigatório'),
                        estadoCivil: Yup.string().required('Campo obrigatório'),
                        nacionalidade: Yup.string().required('Campo obrigatório'),
                        cidade: Yup.string().required('Campo obrigatório'),
                        estado: Yup.string().required('Campo obrigatório'),
                        cep: Yup.string().required('Campo obrigatório'),
                        endereco: Yup.string().required('Campo obrigatório'),
                        genero: Yup.string().required('Campo obrigatório'),
                        status: Yup.string(),
                        dataNacimento: Yup.date().required('Data de nascimento é obrigatório'),
                        email: Yup.string().required('Compo email é orbigatório'),
                        curso: Yup.string(),
                        instituicao: Yup.string(),
                        dataInicioFormacao: Yup.string(),
                        dataFimFormacao: Yup.string(),
                        competenciasEmprego: Yup.string().required("obrigatorio"),
                        empresa: Yup.string(),
                        cargo: Yup.string(),
                        periodoTrabalhadoInicio: Yup.string(),
                        periodoTrabalhadoFim: Yup.string(),
                        isEmpregoAtual: Yup.boolean(),
                        tipo: Yup.string(),
                        fone: Yup.string()
                    })
                }
                onSubmit={async (values) => {
                    const response = await cadastroBancoTalentos({
                        competencias: listCompentenciasHabilidades,
                        experienciaProfissional: experienciasProfissionais,
                        contato: contato,
                        dadosPessoais: dadosPessoais,
                        endereco: endereco,
                        formacoes: formacoes,
                    }, file)

                    if (response.status === 201) {
                        window.location.reload();
                        Notificacao("Sucesso", "Cadastro realizado com sucesso!!!", "success");
                    } else {
                        Notificacao("Error", "Houve um erro ao realizar seu cadastro, verifique os dados e tente novamente!", "danger")
                    }
                }
                }
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className={styles.header} style={{ paddingTop: '15px' }}>
                            <h2 >Cadastre-se em nosso banco de talentos e venha fazer parte do nosso time ✨</h2>
                        </div>
                        <Accordion
                            elevation={4}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography style={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#363f5f', margin: '16px' }}> Sua experiência</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Divider style={{ padding: '6px 0 28px 0' }}></Divider>
                                <div className={styles.sectioSubtitle}>
                                    <h3 className={styles.subTitle}>Experiência acadêmica</h3>
                                    <span>Você pode informar mais de uma experiência acadêmica, caso seja necessário.</span>
                                </div>
                                <div className={styles.rowForm}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', padding: '0px 5px 0px 0px' }}>
                                        <InputLabel id="formacao-label">
                                            Formação
                                        </InputLabel>
                                        <Select style={{ border: '1px solid #ccc' }}
                                            labelId="formacao-label"
                                            name="tipo"
                                            id="tipo"
                                            error={touched.tipo && Boolean(errors.tipo)}
                                            onChange={handleChange}
                                            value={values.tipo}
                                        >
                                            <MenuItem value={""}></MenuItem>
                                            <MenuItem value={"fundamental"}>Fundamental</MenuItem>
                                            <MenuItem value={"medio"}>Médio</MenuItem>
                                            <MenuItem value={"tecnico"}>Tecnico</MenuItem>
                                            <MenuItem value={"superior"}>Superior</MenuItem>
                                        </Select>
                                    </div>
                                    <div style={{ display: 'flex', width: '50%', flexDirection: 'column', padding: '0px 0px 0px 5px' }}>
                                        <InputLabel id="statusFormacao-label">
                                            Status da formação *
                                        </InputLabel>
                                        <Select
                                            style={{ border: '1px solid #ccc' }}
                                            error={touched.status && Boolean(errors.status)}
                                            labelId="statusFormacao-label"
                                            name="status"
                                            id="status"
                                            onChange={handleChange}
                                            value={values.status}
                                        >
                                            <MenuItem value={""}></MenuItem>
                                            <MenuItem value={"incompleto"}>Incompleto</MenuItem>
                                            <MenuItem value={"andamento"}>Andamento</MenuItem>
                                            <MenuItem value={"completo"}>Completo</MenuItem>
                                        </Select>
                                    </div>
                                </div>
                                {
                                    (values.tipo === "superior" || values.tipo === "tecnico")
                                    &&
                                    <div className={styles.rowForm}>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%', padding: '0px 5px 0px 0px' }}><div className={styles.span}>
                                            <label htmlFor="curso">
                                                <span>Curso</span>
                                                <TextField value={values.curso} onChange={handleChange} name="curso" id="curso" className={styles.textField} />
                                            </label>
                                        </div>
                                        </div>

                                        <div style={{ display: 'flex', width: '50%', flexDirection: 'column', padding: '0px 0px 0px 5px' }}>
                                            <div>
                                                <span>Instituição</span>
                                            </div>
                                            <TextField value={values.instituicao} onChange={handleChange} name="instituicao" id="instituicao" className={styles.textField} />
                                        </div>
                                    </div>
                                }
                                {
                                    (values.tipo === "superior" || values.tipo === "tecnico")
                                    &&
                                    <div className={styles.rowForm}>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%', padding: '0px 5px 0px 0px' }}>
                                            <div>
                                                <span>Data de inicio</span>
                                            </div>
                                            <TextField value={values.dataInicioFormacao} onChange={handleChange} name="dataInicioFormacao" id="dataInicioFormacao" className={styles.dateInput} type="date" />
                                        </div>
                                        <div style={{ display: 'flex', width: '50%', flexDirection: 'column', padding: '0px 0px 0px 5px' }}>
                                            <div>
                                                <span>Data de fim</span>
                                            </div>
                                            <TextField value={values.dataFimFormacao} onChange={handleChange} name="dataFimFormacao" id="dataFimFormacao" className={styles.dateInput} type="date" />
                                        </div>
                                    </div>
                                }
                                <div style={{ display: 'flex', height: '100%', justifyContent: 'end', marginTop: '12px' }}>
                                    <button type="button" onClick={
                                        () => createFormacao({
                                            curso: values.curso,
                                            data_inicio: values.dataInicioFormacao,
                                            data_termino: values.dataFimFormacao,
                                            instituicao: values.instituicao,
                                            status: values.status,
                                            tipo: values.tipo
                                        })} className={styles.buttonAdd}>Adicionar</button>
                                </div>
                                {
                                    formacoes.map((formacao, index) => {
                                        if (formacao.tipo === "tecnico" || formacao.tipo === "superior") {
                                            return <div key={index} className={styles.cardFormacao} id="title-formacao">
                                                <div className={styles.header} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div >
                                                        <SchoolIcon sx={{ color: '#6933ff', marginRight: '6px' }} />
                                                        <strong>
                                                            <span style={{ fontSize: '1rem' }}>
                                                                {`${formacao.tipo}`.toLocaleUpperCase()}
                                                            </span>
                                                        </strong>
                                                    </div>
                                                    <div>
                                                        <RemoveCircleOutlineRoundedIcon onClick={() => removeFormacao(formacao)} sx={{ ":hover": { cursor: "pointer", color: "#e52e4d" } }} />
                                                    </div>
                                                </div>
                                                <div className={styles.contentCardFormacao}>
                                                    <strong>Instituição: </strong>{formacao.instituicao}
                                                    <strong>Formação: </strong>{formacao.curso}
                                                    <strong>Status: </strong>{formacao.status}
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'end' }}>
                                                    {moment(formacao.data_inicio).format('DD/MM/YYYY')} A {moment(formacao.data_termino).format('DD/MM/YYYY')}
                                                </div>
                                            </div>
                                        } else {
                                            return <div key={index} className={styles.cardFormacaoEnsinoMedio} >
                                                <SchoolIcon sx={{ color: '#6933ff', }} />
                                                <span>
                                                    <strong>
                                                        {`${formacao.tipo}`.toLocaleUpperCase()}
                                                    </strong>
                                                </span>
                                                <span>
                                                    {formacao.status}
                                                </span>
                                                <RemoveCircleOutlineRoundedIcon onClick={() => removeFormacao(formacao)} sx={{ ":hover": { cursor: "pointer", color: "#e52e4d" } }} />
                                            </div>
                                        }
                                    })
                                }
                                <div className={styles.rowForm} style={{ display: 'flex', width: '100%', flexDirection: 'column', padding: '0px 0px 0px 5px', gap: '6px' }}>
                                    <div style={{ display: 'flex', maxWidth: '600px' }}>
                                        <span>
                                            Competências e Hábilidades
                                        </span>
                                    </div>
                                    <TextField value={values.competenciasEmprego} onChange={handleChange} name="competenciasEmprego" id="competenciasEmprego" />
                                    <div style={{ display: 'flex', height: '100%', justifyContent: 'end', marginTop: '12px' }}>
                                        <button type="button" onClick={() => handleChangetCompetenciasHabilidades(values.competenciasEmprego)} className={styles.buttonAdd}>Adicionar</button>
                                    </div>
                                </div>
                                <div className={styles.contentHabilidades}>
                                    {
                                        listCompentenciasHabilidades.map((competencia, index) => {
                                            if (competencia !== "") {
                                                return (<div key={index} className={styles.competenciasHabilidades}>
                                                    <Chip
                                                        label={competencia}
                                                        variant="outlined"
                                                        onDelete={() => removeCompetenciaHabilidade(competencia)}
                                                    />
                                                </div>)
                                            } else {
                                                return null;
                                            }
                                        })
                                    }
                                </div>
                                <Divider style={{ padding: '6px 0 28px 0' }}></Divider>
                                <div className={styles.sectioSubtitle}>
                                    <h3 className={styles.subTitle}>Experiência Profissional</h3>
                                    <span>Você pode informar mais de uma experiência profissional, caso assim deseje.</span>
                                </div>
                                <div className={styles.rowForm}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', padding: '0px 5px 0px 0px' }}><div className={styles.span}>
                                        <div>
                                            <span>Empresa</span>
                                        </div>
                                        <TextField value={values.empresa} onChange={handleChange} name="empresa" id="empresa" className={styles.textField} />
                                    </div>
                                    </div>
                                    <div style={{ display: 'flex', width: '50%', flexDirection: 'column', padding: '0px 0px 0px 5px' }}>
                                        <div>
                                            <span>Cargo</span>
                                        </div>
                                        <TextField value={values.cargo} onChange={handleChange} name="cargo" id="cargo" className={styles.textField} />
                                    </div>
                                </div>  <div className={styles.rowForm}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', padding: '0px 5px 0px 0px' }}><div className={styles.span}>
                                        <div>
                                            <span>Início</span>
                                        </div>
                                        <TextField value={values.periodoTrabalhadoInicio} onChange={handleChange} name="periodoTrabalhadoInicio" id="periodoTrabalhadoInicio" type="date" className={styles.textField} />
                                    </div>
                                    </div>
                                    {
                                        values.isEmpregoAtual === false && <div style={{ display: 'flex', width: '50%', flexDirection: 'column', padding: '0px 0px 0px 5px' }}>
                                            <div>
                                                <span>Término</span>
                                            </div>
                                            <TextField value={values.periodoTrabalhadoFim} onChange={handleChange} name="periodoTrabalhadoFim" id="periodoTrabalhadoFim" type="date" className={styles.textField} />
                                        </div>}
                                </div>
                                <div style={{ justifyContent: 'flex-end' }} className={styles.rowForm}>
                                    <div>
                                        <span>Emprego atual? </span> <Switch value={values.isEmpregoAtual} onChange={handleChange} name="isEmpregoAtual" id="isEmpregoAtual" />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', height: '100%', justifyContent: 'end', marginTop: '12px' }}>
                                    <button type="button" onClick={() => createExperiencia({
                                        data_termino: values.periodoTrabalhadoFim,
                                        data_inicio: values.periodoTrabalhadoInicio,
                                        emprego_atual: values.isEmpregoAtual,
                                        empresa: values.empresa,
                                        cargo: values.cargo
                                    })} className={styles.buttonAdd}>Adicionar</button>
                                </div>
                                {
                                    experienciasProfissionais.map((experienciaProfissional, index) => {
                                        return <div key={index} className={styles.cardExperiencia}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <WorkIcon sx={{ color: '#6933ff', }} />
                                                <span>
                                                    <strong>Empresa: </strong> {experienciaProfissional.empresa}
                                                </span>
                                                <span>
                                                    <strong>Inicio: </strong>{moment(experienciaProfissional.data_inicio).format("DD/MM/YYYY")}
                                                </span>
                                                {experienciaProfissional.emprego_atual ? <span>
                                                    <strong>{"Emprego atual"} </strong>
                                                </span> : <span>
                                                    <strong> Término:  </strong>  {moment(experienciaProfissional.data_termino).format('DD/MM/YYYY')}
                                                </span>}
                                                <RemoveCircleOutlineRoundedIcon onClick={() => removeExperiencia(experienciaProfissional)} sx={{ ":hover": { cursor: "pointer", color: "#e52e4d" } }} />
                                            </div>
                                        </div>
                                    })
                                }
                            </AccordionDetails>
                        </Accordion>
                        <Accordion elevation={4} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography style={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#363f5f', margin: '16px' }}>
                                    Dados pessoais
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <div className={styles.rowForm}>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%', padding: '0px 5px 0px 0px' }}><div className={styles.span}>
                                            <div>
                                                <span>Nome completo *</span>
                                            </div>
                                            <TextField error={touched.nome && Boolean(errors.nome)} helperText={touched.nome && errors.nome} fullWidth value={values.nome} onChange={handleChange} name="nome" id="nome" className={styles.textField} />
                                        </div>
                                        </div>
                                        <div style={{ display: 'flex', width: '50%', flexDirection: 'column', padding: '0px 0px 0px 5px' }}>
                                            <div>
                                                <span>Data de nascimento *</span>
                                            </div>
                                            <TextField error={touched.dataNacimento && Boolean(errors.dataNacimento)} helperText={touched.dataNacimento && errors.dataNacimento} value={values.dataNacimento} onChange={handleChange} name="dataNacimento" className={styles.dateInput} type="date" id="dataNacimento" />
                                        </div>
                                    </div>
                                    <div className={styles.rowForm} >
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%', padding: '0px 5px 0px 0px' }}>
                                            <div><span>E-mail</span></div>
                                            <TextField error={touched.email && Boolean(errors.email)} helperText={touched.email && errors.email} value={values.email} onChange={handleChange} name="email" id="email" className={styles.textField} />
                                        </div>
                                        <div style={{ display: 'flex', width: '50%', flexDirection: 'column', padding: '0px 0px 0px 5px' }}>
                                            <div><span>Nacionalidade</span></div>
                                            <TextField error={touched.nacionalidade && Boolean(errors.nacionalidade)} helperText={touched.nacionalidade && errors.nacionalidade} name="nacionalidade" id="nacionalidade" value={values.nacionalidade} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div>
                                        <div><span>Fone:</span></div>
                                        <div style={{ width: '100%', marginBottom: '12px' }}>
                                            <TextField fullWidth error={touched.fone && Boolean(errors.fone)} helperText={touched.fone && errors.fone} name="fone" id="fone" value={values.fone} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className={styles.rowForm}>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%', padding: '0px 5px 0px 0px' }}>
                                            <FormControl
                                                error={touched.genero && Boolean(errors.genero)}
                                            >
                                                <FormLabel id="genero-label">Gênero</FormLabel>
                                                <RadioGroup name="genero" id="genero" defaultValue="masculino" value={values.genero} onChange={handleChange} row>
                                                    <FormControlLabel value="masculino" control={<Radio />} label="Masculino" />
                                                    <FormControlLabel value="feminino" control={<Radio />} label="Feminino" />
                                                    <FormControlLabel value="outro" control={<Radio />} label="Outro" />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                        <div style={{ display: 'flex', width: '50%', flexDirection: 'column', padding: '0px 0px 0px 5px' }}>
                                            <FormControl
                                                error={touched.estadoCivil && Boolean(errors.estadoCivil)}
                                            >
                                                <FormLabel id="estadoCivil-label">Estado civil</FormLabel>
                                                <RadioGroup
                                                    defaultValue="solteiro"
                                                    name="estadoCivil"
                                                    id="estadoCivil"
                                                    row
                                                    value={values.estadoCivil}
                                                    onChange={handleChange}
                                                >
                                                    <FormControlLabel value="solteiro" control={<Radio />} label="Solteiro(a)" />
                                                    <FormControlLabel value="casado" control={<Radio />} label="Casado(a)" />
                                                    <FormControlLabel value="viuvo" control={<Radio />} label="Viuvo(a)" />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className={styles.rowForm} style={{ marginBottom: "14px" }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%', padding: '0px 5px 0px 0px' }}><div className={styles.span}>
                                            <div>
                                                <span>Cep</span>
                                            </div>
                                            <TextField error={touched.cep && Boolean(errors.cep)} helperText={touched.cep && errors.cep} value={values.cep} onChange={handleChange} name="cep" id="cep" type="number" className={styles.textField} />
                                        </div>
                                        </div>
                                        <div style={{ display: 'flex', width: '50%', flexDirection: 'column', padding: '0px 0px 0px 5px' }}>
                                            <div>
                                                <span>Endereço</span>
                                            </div>
                                            <TextField error={touched.endereco && Boolean(errors.endereco)} helperText={touched.endereco && errors.endereco} value={values.endereco} onChange={handleChange} name="endereco" id="endereco" className={styles.textField} />
                                        </div>
                                    </div>
                                    <div className={styles.rowForm}>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%', padding: '0px 5px 0px 0px' }}>
                                            <div className={styles.span}>
                                                <div>
                                                    <span>Estado</span>
                                                </div>
                                                <TextField error={touched.estado && Boolean(errors.estado)} helperText={touched.estado && errors.estado} value={values.estado} onChange={handleChange} name="estado" id="estado" className={styles.textField} />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', width: '50%', flexDirection: 'column', padding: '0px 0px 0px 5px' }}>
                                            <div>
                                                <span>Cidade</span>
                                            </div>
                                            <TextField error={touched.cidade && Boolean(errors.cidade)} helperText={touched.cidade && errors.cidade} value={values.cidade} onChange={handleChange} name="cidade" id="cidade" className={styles.textField} />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', height: '100%', justifyContent: 'end', marginTop: '12px' }}>
                                        <button type="button" onClick={() => {
                                            handleEndereco({ cep: values.cep, cidade: values.cidade, endereco: values.endereco, estado: values.estado })
                                            handleContato({ email: values.email, fone: values.fone })
                                            handleSaveDadosPessoais({
                                                data_nascimento: values.dataNacimento,
                                                estada_civil: values.estadoCivil,
                                                genero: values.genero,
                                                nacionalidade: values.nacionalidade,
                                                name: values.nome
                                            })
                                        }
                                        } className={styles.buttonAdd}>Salvar</button>
                                    </div>
                                    <div>
                                        <span>Anexar curriculo pdf</span>
                                    </div>
                                    <input className={styles.inpuFile} accept="application/pdf" type="file" onChange={e => {
                                        const files = e.target.files;
                                        if (files && files.length > 0) {
                                            setFile(files[0]);
                                        }
                                    }} name="curriculo" id="curriculo" />
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <div className={styles.footer}>
                            <button className={styles.buttonCadastre} disabled={dadosPessoais.name && contato.email !== "" ? false : true} type="submit" >Cadastrar-se</button>
                        </div>
                    </form>
                )}
            </Formik>
        </Box>
    </div >)
}
