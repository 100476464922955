import { ThemeProvider } from '@mui/material'
import { ReactNotifications } from 'react-notifications-component'
/**/ import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { Tema } from './assets/themes/tema'
import Footer from './components/footer/footer'
import Header from './components/header/header'
import ScrollToTop from './components/header/ScrollToTop'
import Empresas from './pages/empresas/empresas';
import CarrinhoPage from './pages/empresas/subpage/carrinho'
import CategoriaPage from './pages/empresas/subpage/categorias'
import CriarConta from './pages/empresas/subpage/criarConta'
import EmpresaDetail from './pages/empresas/subpage/empresaDetail'
import EmpresasFromCity from './pages/empresas/subpage/empresas'
import Login from './pages/empresas/subpage/login'
import PedidosPage from './pages/empresas/subpage/pedidos'
import ProdutoDetail from './pages/empresas/subpage/produtoDetail'
import Entregador from './pages/entregador/entregador'
//import Franqueado from './pages/franqueado/franqueado'
import Home from './pages/home/home'
import Agradecimento from './pages/parceiro/components/secao_form/agradecimento'
import Parceiro from './pages/parceiro/parceiro'
import Sobre from './pages/sobre/sobre'
import Termos from './pages/termos/termos'
import { TrabalheConosco } from './pages/trabalhe-conosco/trabalhe-conosco'
import { PageFormularioTrabalheConosco } from './pages/trabalhe-conosco/components/PageFormularioTrabalheConosco/PageFormularioTrabalheConosco'

function App() {
  return (
    <div>
      {/*<Home />

      {' '}*/}
      <ThemeProvider theme={Tema}>
        <ReactNotifications isMobile={true} />

        <BrowserRouter>
          <ScrollToTop children={undefined} />
          <Header />
          <Routes>
            {/**/}
            <Route path="/" element={<Home />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/Entregador" element={<Entregador />} />
            {
              /*
                  <Route path="/Franqueado" element={<Franqueado />} />
              */
            }
            <Route path="/Parceiro" element={<Parceiro />} />
            <Route path="/Sobre" element={<Sobre />} />
            <Route path="/TrabalheConosco" element={<TrabalheConosco />} />
            <Route path="/Agradecimento" element={<Agradecimento />} />
            <Route path="/termos" element={<Termos />} />
            <Route path="/empresas" element={<Empresas />} />
            <Route path="/empresas/cidade/:id/:cidade" element={<EmpresasFromCity />} />
            <Route path="/empresa/:slugEmpresa" element={<EmpresaDetail />} />
            <Route path="/empresas/:cidade/:idCategoria" element={<CategoriaPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/pedidos" element={<PedidosPage />} />
            <Route path="/carrinho" element={<CarrinhoPage />} />
            <Route path="/criar-conta" element={<CriarConta />} />
            <Route path="/formTrabalheConosco" element={<PageFormularioTrabalheConosco />} />
            <Route path="/produto/:idProduto" element={<ProdutoDetail />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  )
}

export default App
