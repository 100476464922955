import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Box, Grid, Typography } from '@mui/material'
//MUI
import Button from '@mui/material/Button'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'

import api from '../secao_select/repositorio'
//CSS
import styles from '../secao_select/style.module.css'

//IMAGENS
const entregador = require('../../../../assets/images/homem_entregador1.png')
const check = require('../../../../assets/images/check.png')

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 240,
    color: '#262626'
  }
}))
const SecaoSelect = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [nomeCidade, setNomeCidade] = useState([])

  /**/ const getCidade = async () => {
    await api.get('api/v1/cidades_ativas').then(res => {
      setNomeCidade(res.data.data)
      console.log(nomeCidade)
    })
  }

  useEffect(() => {
    getCidade()
  }, [])

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      marginTop: 12,
      width: '100%',
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Typography sx={{
          fontSize: '1.8rem',
          marginBottom: 8,
        }}>
          Faça parte do nosso time de entregadores
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <img
            src={check}
            alt="simbolo de verificado"
            className={styles.check1}
          />
          <p className={styles.requisito}>1 - Veículo próprio</p>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <img
            src={check}
            alt="simbolo de verificado"
            className={styles.check2}
          />
          <p className={styles.requisito}>2 - CNH(Carteira de motorista) </p>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <p className={styles.texto_busca}>
            Busque sua cidade para falar com a gente.
          </p>
        </Box>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <Button
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
            sx={{
              borderRadius: 2,
              width: 238,
              height: 48,
              background: '#FFEC0D',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              alignContent: 'center',
              color: '#262626',
              '&:hover': {
                backgroundColor: '#FFEC0D',
                opacity: [0.8, 0.8, 0.8]
              }
            }}
          >
            <p>Cidades</p>
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button'
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <a
              href="https://api.whatsapp.com/send?phone=5599991547847"
              className={styles.links}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MenuItem onClick={handleClose} disableRipple>
                Açailandia-MA
              </MenuItem>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5562998463641"
              className={styles.links}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MenuItem onClick={handleClose} disableRipple>
                Aparecida de Goiania-GO
              </MenuItem>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5563991094817"
              className={styles.links}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MenuItem onClick={handleClose} disableRipple>
                Araguaina-TO
              </MenuItem>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5599991045490&text=Quero%20Ser%20Entregador!"
              className={styles.links}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MenuItem onClick={handleClose} disableRipple>
                Balsas-MA
              </MenuItem>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5563992921098"
              className={styles.links}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MenuItem onClick={handleClose} disableRipple>
                Colinas-TO
              </MenuItem>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5589994366243"
              className={styles.links}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MenuItem onClick={handleClose} disableRipple>
                Floriano-PI
              </MenuItem>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5599985096702"
              className={styles.links}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MenuItem onClick={handleClose} disableRipple>
                Imperatriz-MA
              </MenuItem>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5577999090404"
              className={styles.links}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MenuItem onClick={handleClose} disableRipple>
                Luiz Eduardo Magalhães-BA
              </MenuItem>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5594991832716"
              className={styles.links}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MenuItem onClick={handleClose} disableRipple>
                Maraba-PA
              </MenuItem>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5563992963915"
              className={styles.links}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MenuItem onClick={handleClose} disableRipple>
                Palmas-TO
              </MenuItem>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5594974006794"
              className={styles.links}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MenuItem onClick={handleClose} disableRipple>
                Paraupebas-PA
              </MenuItem>
            </a>

            <a
              href="https://api.whatsapp.com/send?phone=5594992945184"
              className={styles.links}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MenuItem onClick={handleClose} disableRipple>
                Redenção-PA
              </MenuItem>
            </a>

            <a>
              <MenuItem onClick={handleClose} disableRipple>
                Salvador-BA(Ainda nao disponivel)
              </MenuItem>
            </a>
          </StyledMenu>
          <div className={styles.divAmarela}></div>
        </Grid>
      </Box>
      <Box sx={{
        background: '#FFEC0D',
        borderRadius: 50,
        width: 300,
        height: 300,
        marginTop: 4,
      }}>
        <img
          src={entregador}
          alt="Homem sentado em moto foguete"
          style={{
            width: 300,
            height: 300,
            marginLeft: 15,
          }}
        />
      </Box>
    </Box>
  )
}

export default SecaoSelect
