//MUI
import { Box, Card, Grid } from '@mui/material'
//REACT
import React, { useEffect, useState } from 'react'

//CSS

import styles from '../secao_form/style.module.css'
import { getAllActiveCities, sendEmailToPartner } from '../../repository/partnerRepository'
import { htmlAssuntoEmail } from '../AssuntoEmailParceiro/htmlAssuntoEmail'
import Notificacao from '../../../../components/notification'

type EstadoType = {
  id: number,
  sigla: string,
  nome: string
}

type CidadeType = {
  id: number,
  nome: string,
  ativo: number,
  estado_id: number,
  estado: EstadoType,
  sigla: string,
}


const homemFoguete = require('../../../../assets/images/homem_olhando_foguete.png')
const SecaoForm = () => {

  const [selectBox, setSelectBox] = useState(false)
  const [name, setName] = useState('')
  const [emailUser, setEmailUser] = useState('')
  const [phone, setPhone] = useState('')
  const [company, setCompany] = useState('')
  const [cityId, setCityId] = useState<number | null>(null)
  const [allCities, setAllCities] = useState(Array<CidadeType>)
  const [nomeCidade, setNomeCidade] = useState('Cidade não encontrada')
  const [onReload, setOnReload] = useState(false);

  async function handleSubmit(): Promise<void>{

    const fieldsForm = {
      userName: name,
      userEmail: emailUser,
      userTelefone: phone,
      userEmpresa: company,
      userCidade: nomeCidade,
    };

    const htmlAtualizado = replaceFieldsInHtml(htmlAssuntoEmail, fieldsForm);

    const data = {
      assunto: 'OLÁ, GOSTARIA DE ME TORNAR UM PARCEIRO DO VOKERÊ PARA IMPULSIONAR AS VENDAS DA MINHA EMPRESA.',
      mensagem: htmlAtualizado,
      cityId: cityId
    }

    if(name.length > 3 && emailUser.length > 3 && phone.length > 3 && company.length > 3 && selectBox){
      const response = await sendEmailToPartner(data)
      if (response){
        Notificacao('Sucesso!', `Email enviado com sucesso!`, 'success');
        setOnReload(!onReload);
      }else{
        Notificacao('Error', `Erro ao enviar email, tente novamente!`, 'danger');
      }
    }
    
  }

  function replaceFieldsInHtml(html: string, dados: { [chave: string]: string }): string {
    return Object.keys(dados).reduce((htmlAtualizado, chave) => {
        const marcador = `{{${chave}}}`;
        return htmlAtualizado.replace(new RegExp(marcador, 'g'), dados[chave]);
    }, html);
}

  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight
  });

  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight
    });

  useEffect(() => {
    if (cityId){
      const selectedCity = allCities.find(city => city.id === cityId);
      setNomeCidade(selectedCity ? selectedCity.nome : 'Cidade não encontrada');
    }
  }, [cityId]);

  useEffect(() => {
    setSelectBox(false);
    setName('');
    setEmailUser('');
    setPhone('');
    setCompany('');
    setCityId(null);
    setAllCities([]);
    setNomeCidade('');

    getAllActiveCities()
    .then(res => {
      setAllCities(res.data.data);
    })

    window.onresize = updateSize
  }, [onReload]);

  return (
    <Box 
    sx={{
      background: '#FFEC0D',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: size.x > 800 ? 'space-around' : 'center',
      textAlign: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      width: '100%',
    }}>
      <Box 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}>
        <h1 style={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '35px',
          lineHeight: '47px',
          color: '#323131',
          marginTop: '80px',
          marginLeft: '3%',
          marginRight: '3%',
        }}>
          Quando você vende todo mundo ganha.
        </h1>
        <img
          style={{
            width: size.x > 1200 ? '400px' : size.x > 1000 ? '350px' : '300px',
            marginTop: size.x > 1200 ? '311px' : 0,
            marginBottom: size.x < 1200 ? 0 : 'auto',
            marginLeft: size.x < 800 ? 10 : 0,
          }}
          src={homemFoguete}
          alt="Homem sentado em moto foguete"
        />
      </Box>
      <div 
        style={{
        width: size.x > 800 ? '500px' : '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Card sx={{
          border: '1px solid #FFFFFE',
          width: '95%',
          marginTop: size.x > 1200 ? '80px' : 0,
          marginBottom: '40px',
          background: '#FFFFFE',
          borderRadius: '25px',
        }} elevation={7}>
          <Box>
            <h3 style={{
              marginTop: '20px',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '17px',
              lineHeight: '144%',
              /* or 33px */

              letterSpacing: '0.045em',

              color: '#323131',
            }}>Seja um parceiro Vokerê</h3>
            <p style={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '144%',
              /* or 27px */

              letterSpacing: '0.045em',

              color: '#646363',
            }}>
              Impulsione as vendas do seu negócio
              <br /> Entre pro nosso catálogo.
            </p>
          </Box>
          <Grid hidden>
            <label htmlFor="">Parceiro:</label>
            <input type="text" name="Tipo" value={'Parceiro'} />
          </Grid>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <input
              style={{
                marginBottom: '12px',
                fontSize: '13px',
                width: '90%',
                height: '41px',
                background: '#F2EFEF',
                border: '1px solid rgba(194, 192, 192, 0.9)',
                borderRadius: '30px',
                paddingLeft: '14px',
              }}
              value={name}
              onChange={(event) => setName(event.target.value)}
              type="text"
              name="Nome:"
              placeholder="Digite seu nome completo"
              required
            />
            <input
              style={{
                marginBottom: '12px',
                fontSize: '13px',
                width: '90%',
                height: '41px',
                background: '#F2EFEF',
                border: '1px solid rgba(194, 192, 192, 0.9)',
                borderRadius: '30px',
                paddingLeft: '14px',
              }}
              value={emailUser}
              onChange={(event) => setEmailUser(event.target.value)}
              type="email"
              name="email"
              placeholder="E-mail"
              required
            />
            <input
              style={{
                marginBottom: '12px',
                fontSize: '13px',
                width: '90%',
                height: '41px',
                background: '#F2EFEF',
                border: '1px solid rgba(194, 192, 192, 0.9)',
                borderRadius: '30px',
                paddingLeft: '14px',
              }}
              value={company}
              onChange={(event) => setCompany(event.target.value)}
              type="text"
              name="NomeEmpresa"
              placeholder="Nome da empresa. "
            />
            <input
              style={{
                marginBottom: '12px',
                fontSize: '13px',
                width: '90%',
                height: '41px',
                background: '#F2EFEF',
                border: '1px solid rgba(194, 192, 192, 0.9)',
                borderRadius: '30px',
                paddingLeft: '14px',
              }}
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
              type="text"
              name="Celular"
              placeholder="Celular - numero WhatsApp: "
            />
            <select
              required
              style={{
                marginBottom: '12px',
                fontSize: '13px',
                width: '93%',
                height: '41px',
                background: '#F2EFEF',
                border: '1px solid rgba(194, 192, 192, 0.9)',
                borderRadius: '30px',
                paddingLeft: '14px',
              }}
              name="Cidades"
              onChange={event => setCityId(parseInt(event.target.value, 10))}
            >
              <option value=''>Selecione a cidade</option>
              {allCities.map(cidade => (
                    <option key={cidade.id} value={cidade.id}>
                      {cidade.nome}
                    </option>
                  )
                )
              }

            </select>

            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              width: '90%',
              marginBottom: '12px',
              marginTop: '12px',
            }}>
              <div style={{
                display: 'flex',
                alignItems: 'space-between',
                justifyContent: 'space-between',
                textAlign: 'start',
              }}>
                <input
                  style={{
                    color: '#FFEC0D',
                    background: '#FFEC0D',
                    border: '1px solid #FFFFFE',
                    borderRadius: '5px',
                  }}
                  checked={selectBox}
                  type="checkbox"
                  onChange={(event => setSelectBox(event.target.checked))}
                  name="Validação: "
                  required
                />
                <p style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '19px',
                    color: '#403F3F',
                    marginLeft: '15px',
                }}>
                  <strong>Concordo</strong> que o Vokerê entre em contato comigo
                  por telefone, email e whatsapp.
                </p>
              </div>
            </Box>
            <input
              type="hidden"
              name="_next"
              value="https://euvokere.com.br/Agradecimento"
            />
          </Box>
          <Grid sx={{ textAlign: 'center' }}>
            <div className={styles.div_botao}>
              <button
                //type='submit'
                onClick={handleSubmit}
                className={styles.botao}>
                <p className={styles.t_botao}> Enviar</p>
              </button>
            </div>
          </Grid>
        </Card>
      </div>
    </Box>
  )
}

export default SecaoForm
