import Grid from "@mui/material/Grid"
import { BodyTrabalheConosco } from "./components/Body/body"
import styles from './styles.module.css'


export const TrabalheConosco = () => {
    return (
        <Grid className={styles.body}>
            <BodyTrabalheConosco />
        </Grid>
    )
}
