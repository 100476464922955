import { getUrlAPIv3 } from "../api";

interface Formacao {
    tipo: string,
    status: string,
    data_inicio: string | null,
    data_termino: string | null,
    curso: string | null,
    instituicao: string | null
}
interface ExperienciaProfissional {
    data_inicio: string,
    data_termino: string,
    empresa: string,
    cargo: string,
    emprego_atual: boolean
}

interface UserDadosPessoais {
    name: string,
    estada_civil: string,
    genero: string,
    data_nascimento: string,
    nacionalidade: string,
}

interface Endereco {
    cep: string,
    cidade: string,
    estado: string,
    endereco: string,
}

interface PropsCadastro {
    formacoes: Formacao[],
    dadosPessoais: UserDadosPessoais,
    experienciaProfissional: ExperienciaProfissional[],
    competencias: string[],
    endereco: Endereco,
    contato: Contato
}
interface Contato {
    fone: string,
    email: string
}

export const cadastroBancoTalentos = async (values: PropsCadastro, curriculo: File | null) => {
    let url = `${getUrlAPIv3}/talentos`;
    let objFormacao: Formacao[] = [];
    let objExperiencia: ExperienciaProfissional[] = [];
    let hasFormacao = true;
    let hasExperiencias = true;
    const formData = new FormData();
    values.formacoes.forEach((formacao) => {
        if (formacao.tipo === "tecnico" || formacao.tipo === "superior") {
            hasFormacao = true;
            objFormacao.push({
                curso: formacao.curso,
                instituicao: formacao.instituicao,
                data_termino: formacao.data_termino,
                tipo: formacao.tipo,
                status: formacao.status,
                data_inicio: formacao.data_inicio
            })
        } else if (formacao.tipo) {
            objFormacao.push(
                {
                    curso: null,
                    instituicao: null,
                    data_termino: null,
                    tipo: formacao.tipo,
                    status: formacao.status,
                    data_inicio: null
                }
            )
            hasFormacao = true;
        } else {
            hasFormacao = false;
        }
    })
    values.experienciaProfissional.forEach((experiencia) => {
        if (experiencia.data_inicio) {
            objExperiencia.push(
                {
                    "empresa": experiencia.empresa,
                    "data_inicio": experiencia.data_inicio,
                    "data_termino": experiencia.data_termino,
                    "emprego_atual": experiencia.emprego_atual,
                    "cargo": experiencia.cargo
                }
            );
            hasExperiencias = true;
        } else {
            hasExperiencias = false;
        }
    })
    if (hasFormacao) {
        formData.append('formacoes', JSON.stringify(objFormacao));
    }
    if (hasExperiencias) {
        formData.append('experiencia_profissional', JSON.stringify(objExperiencia));
    }
    formData.append('contato', JSON.stringify(values.contato));
    formData.append('endereco', JSON.stringify(values.endereco));
    if (values.competencias) {
        formData.append('habilidades', JSON.stringify(values.competencias))
    }
    formData.append('name', values.dadosPessoais.name);
    formData.append('estado_civil', values.dadosPessoais.estada_civil);
    formData.append('genero', values.dadosPessoais.genero);
    formData.append('nacionalidade', values.dadosPessoais.nacionalidade);
    formData.append('data_nascimento', values.dadosPessoais.data_nascimento);

    if (curriculo) {
        formData.append('curriculo', curriculo);
    }
    const response = await fetch(url, {
        method: "POST",
        body: formData,
    });
    return response;
}