//MUI
import { Box } from '@mui/material'

//CSS
import styles from '../secao_nosso_app/style.module.css'

//IMAGENS
const imgcllrs = require('../../../../assets/images/imgcllrs.png')
const playstore = require('../../../../assets/images/google_play_badge.png')
const appstore = require('../../../../assets/images/Download_App_Store.png')

function NossoApp() {

  return (
    <Box className={styles.body}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <img
          src={imgcllrs}
          alt="img celulares vokere"
          className={styles.cllrvkr}
        />
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'start',
        textAlign: 'start',
      }}>
        <Box>
          <Box>
            <h3 className={styles.tituloapp}>Já conhece nosso app?</h3>
          </Box>

          <Box>
            <p className={styles.textoapp}>
              Baixe o app, veja nosso catalago e faça o seu pedido.
            </p>
          </Box>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box>
            <a href="https://apps.apple.com/br/app/voker%C3%AA/id1476149761">
              <img
                src={appstore}
                alt="selo app store"
                className={styles.seloappstore}
              />
            </a>
          </Box>
          <Box>
            <a href="https://play.google.com/store/apps/details?id=br.com.hollic.vokere&hl=pt_BR&gl=US">
              <img
                src={playstore}
                alt="playstore"
                className={styles.playstore}
              />
            </a>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default NossoApp
